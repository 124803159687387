import React from 'react'
import { Button, Form, Alert } from 'react-bootstrap'
import FileDropzone from 'src/components/system_wide/file_dropzone'
import fileUploader from 'src/components/system_wide/file_uploader'
import { getConstants } from 'src/lib/constants'
import { error, success } from './notification'

class UpoloadForm extends React.Component {
  static defaultProps = {
    tenant: '',
    position: 99,
    media_id: '',
    product_id: '',
    category_id: '',
    preview: false,
    onConfirm: () => {},
  }

  state = {
    filename: '',
    uploading: false,
    failed: false,

    show_error_message: false,
    show_success_message: false,
  }

  action = getConstants().actions
  file = null
  uploader = null

  handleDrop = file => {
    const { name } = file
    this.file = file
    this.setState({
      errored: [],
      filename: name,
      uploading: false,
      show_success_message: false,
      show_error_message: false,
    })
  }

  handleCancel = () => {
    this.file = null
    this.setState({
      msg: '',
      filename: '',
      uploading: false,
      show_success_message: false,
      show_error_message: false,
    })
  }

  onConfirmModal = async () => {
    let { tenant, position, product_id: productID, category_id: categoryID, media_id } = this.props
    this.setState({ uploading: true })
    if (
      await fileUploader(
        `/api/media/handle/${tenant}`,
        this.file,
        {
          position,
          action: media_id !== '' ? this.action.edit : this.action.create,
          id: media_id !== '' ? media_id : null,
          productID,
          categoryID,
        },
        () => {}
      )
    ) {
      //success upload restore
      this.onRestoreSuccess()
    } else {
      //error upload restore
      this.onRestoreError()
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  onRestoreError = () => {
    error('Impossibile caricare il file')
    this.setState({ uploading: false, show_success_message: true })
    setTimeout(() => {
      this.setState({ show_success_message: false })
      this.handleCancel()
    }, 2000)
  }

  onRestoreSuccess = () => {
    success('File caricato con successo')
    this.setState({ uploading: false, show_success_message: true })
    setTimeout(() => {
      this.setState({ show_success_message: false })
      this.props.onConfirm()
    }, 2000)
  }

  render() {
    let { uploading, filename, show_success_message, show_error_message } = this.state

    return (
      <div>
        <div className="mb-3">
          <div className="mb-3 text-muted">
            <small>Trascina o clicca sull'area sottostante per caricare un file</small>
          </div>
          <Form.Group>
            <FileDropzone onDrop={this.handleDrop} dimmed={uploading} />
            {filename !== '' && (
              <div className="d-flex flex-row align-items-center">
                <b>Filename</b>: {filename}
                <i className="fa fa-trash mt-1 ms-auto" onClick={this.handleCancel} style={{ cursor: 'pointer' }}></i>
              </div>
            )}
          </Form.Group>
          {show_success_message === true && (
            <Alert className="mt-3" variant="success">
              Upload avvenuto con successo
            </Alert>
          )}
          {show_error_message === true && (
            <Alert className="mt-3" variant="danger">
              Errore durante l'upload del file
            </Alert>
          )}
        </div>
        {this.props.preview === true && this.file !== null && (
          <div className="my-3">
            <img src={URL.createObjectURL(this.file)} alt="preview logo" style={{ maxWidth: '20rem' }} />
          </div>
        )}
        <div>
          <Button onClick={this.onConfirmModal} disabled={this.file === null}>
            {uploading === true && (
              <div
                className="spinner-border spinner-border-sm me-2"
                role="status"
                style={{ color: 'var(--contrast-primary)' }}>
                <span className="sr-only">Loading...</span>
              </div>
            )}
            Upload
          </Button>
        </div>
      </div>
    )
  }
}

export default UpoloadForm
