import React from 'react'
import { Outlet } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { withRouter } from 'src/lib/router/with_router'
import AdminHeader from 'src/components/admin/header'
import { ReactNotifications } from 'react-notifications-component'
import Footer from 'src/components/system_wide/footer'
import ThemeContext from 'src/components/system_wide/theme_context'

class NLApp extends React.Component {
  state = { ready: false, tenant: 'admin' }

  componentDidMount() {
    let { location, navigate } = this.props.router
    let tenant = location.pathname.split('/')?.[1] || 'admin'
    this.setState({ ready: true })
    if (!location.pathname.split('/')?.[1]) return setTimeout(() => navigate(`/${tenant}`, { replace: true }))
  }

  componentDidCatch(error, info) {
    console.log('error: ', error)
    console.log('info: ', info)
  }

  render() {
    let { location } = this.props.router
    let is_login = location?.pathname?.includes('login') ?? false
    return (
      <>
        <HelmetProvider>
          <ThemeContext.Provider value={this.theme}>
            {this.state.ready === true && (
              <>
                <ReactNotifications />
                {is_login === false && <AdminHeader containerSize={'fluid'} />}
                <main className={`layout`}>
                  <Outlet {...this.props} />
                </main>
                <Footer containerSize={'fluid'} />
              </>
            )}
          </ThemeContext.Provider>
        </HelmetProvider>
      </>
    )
  }
}

export default withRouter(NLApp)
