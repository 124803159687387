import React from 'react'
import moment from 'moment'
import { Link, useParams, useLocation } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
//import { error } from '/components/system_wide/notification'

const ListItemMobile = ({ tenant }) => {
  let params = useParams()
  let location = useLocation()
  let to_path = `/admin/${tenant.name}/preview`
  let current_section = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

  if (['preview', 'categories', 'products', 'ingredients', 'tags'].includes(current_section)) {
    to_path = `/admin/${tenant.name}/${current_section}`
  }
  return (
    <>
      <style jsx="true">{`
        .ellipsed {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .list-group-item {
          border-left: 5px solid lightgray;
        }
        .list-group-item-active {
          border-left: 5px solid var(--bs-primary);
        }
        .list-group-item:hover {
          background: var(--bs-light);
        }
      `}</style>
      <Nav.Link className="" key={tenant.name} as={Link} to={to_path}>
        <div
          className={`container-fluid ${
            params.store_name === tenant.name ? 'list-group-item-active' : 'list-group-item'
          }`}>
          <div className="d-flex">
            <div className="flex-fill">
              <b>{tenant.name}</b>
              <br />
              <small className="text-muted">Data creazione: {moment(tenant.created_at).format('YYYY-MM-DD')}</small>
            </div>
          </div>
        </div>
      </Nav.Link>
    </>
  )
}

export default ListItemMobile
