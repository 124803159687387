import React from 'react'
import MenuLayout from 'src/components/menu_layout'
import ProductMenu from 'src/components/menu_digitale/product_menu'
import { withRouter } from 'src/lib/router/with_router'

class ProductsMenuPage extends React.Component {
  render() {
    console.log('product', this.props)
    let { category_id, tenant_name } = this.props.router.params
    return (
      <MenuLayout tenant={tenant_name} pageTitle={'Menu digitale'}>
        <ProductMenu category_id={category_id} />
      </MenuLayout>
    )
  }
}

export default withRouter(ProductsMenuPage)
