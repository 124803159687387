import React from 'react'
import { connect } from 'react-redux'

const mstp = state => {
  let { name } = state.storeManager.current_tenant
  return { name }
}
class Header extends React.Component {
  render() {
    let { name } = this.props
    return <h3 style={{ color: name ? '' : 'transparent' }}>{name || 'Punto vendita'}</h3>
  }
}
export default connect(mstp)(Header)
