import React from 'react'
import { connect } from 'react-redux'

import HorizontalSidebar from 'src/components/system_wide/horizontal_sidebar'
const mstp = state => ({ is_management_account: state.userInfo.is_management_account })

class TenantManagerSidebar extends React.Component {
  render() {
    let sidebarItems = [
      {
        name: 'tenant_list',
        icon: 'fas fa-store',
      },
      {
        name: 'preview',
        text: 'Preview',
        icon: 'fas fa-address-card',
        pathname: '/admin/[store_name]/preview',
        caps: { has: [] },
      },
      {
        name: 'categories',
        text: 'Categorie',
        icon: 'fas fa-address-card',
        pathname: '/admin/[store_name]/categories',
        caps: { has: [] },
      },
      {
        name: 'products',
        text: 'Prodotti',
        icon: 'fas fa-person-digging',
        pathname: '/admin/[store_name]/products',
        caps: { has: [] },
      },
      {
        name: 'ingredients',
        text: 'Ingredienti',
        icon: 'fas  fa-helmet-safety',
        pathname: '/admin/[store_name]/ingredients',
        caps: { has: [] },
      },
      {
        name: 'tags',
        text: 'Tags',
        icon: 'fas fa-clock',
        pathname: '/admin/[store_name]/tags',
        caps: { has: [] },
      },
      {
        name: 'remove',
        text: 'Elimina',
        icon: 'fas fa-trash',
        pathname: '/admin/[store_name]/remove_tenant',
        caps: { has: ['bo_delete_tenant'] },
      },
    ]

    if (this.props.is_management_account === 0) {
      sidebarItems = sidebarItems.filter(s => !['tenant_list'].includes(s.name))
    }
    return <HorizontalSidebar sidebarItems={sidebarItems} {...this.props} />
  }
}
export default connect(mstp)(TenantManagerSidebar)
