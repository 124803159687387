import React from 'react'
import { Link } from 'react-router-dom'
import { Nav, Navbar, Offcanvas } from 'react-bootstrap'

import ThemeContext from '../theme_context'
import styles from 'src/components/system_wide/header/header.module.css'

export class BaseHeader extends React.PureComponent {
  static contextType = ThemeContext

  static defaultProps = {
    mobileMenuTitle: '',
    brandUrl: '',
    headerSize: 'fluid',
    responsive: true,
  }

  render() {
    let [HeaderContent, MobileMenuContent = false] = React.Children.toArray(this.props.children)
    let { mobileMenuTitle, headerSize, responsive } = this.props

    return (
      <Navbar
        expand="lg"
        className={`d-flex align-items-center container-fluid sticky-top ${styles.header}
          border-bottom contrast-dark collapse-icon-dark`}
        variant="dark">
        <div className={`container-${headerSize} d-flex align-items-center`}>
          <Navbar.Brand className="d-flex">
            <div className="align-self-center">
              <img src="/logo_nicelive_trasp.png" style={{ width: '3rem' }} alt="logo nicelive" />
            </div>
            <div className={`align-self-center ${styles.logo_text}`}>
              <i>iceMenu</i>
            </div>
          </Navbar.Brand>

          <Nav
            aria-controls="navbarScroll"
            className={`mr-sm-2 dropdown-menu-right ${responsive ? 'd-none d-lg-flex' : 'd-flex'} align-items-center`}>
            {HeaderContent}
          </Nav>

          <Navbar.Toggle />
        </div>

        {MobileMenuContent !== false && (
          <Navbar.Offcanvas placement="end" className={`d-block d-lg-none ${styles.mobile_menu} variant-light`}>
            <Offcanvas.Header closeButton className="mobile-menu-header py-2">
              <Offcanvas.Title className="py-1 text-contrast-dark" as="div">
                <strong>{mobileMenuTitle}</strong>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav aria-controls="navbarScroll" className="mr-sm-2 dropdown-menu-right">
                {MobileMenuContent}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        )}
      </Navbar>
    )
  }
}

export const HeaderContent = ({ children }) => children
export const MobileMenuContent = ({ children }) => children
export const MobileMenuItem = ({ onClick, href, active, children }) => {
  //let { mobileMenuVariant } = React.useContext(ThemeContext)
  let mobileMenuVariant = 'light'
  return (
    <Nav.Link
      onClick={onClick}
      as={Link}
      to={href}
      eventKey={href}
      active={active}
      className={`${styles.mobile_menu_item} py-0 text-contrast-${mobileMenuVariant}`}
      style={{ fontSize: '110%' }}>
      {children}
    </Nav.Link>
  )
}
export { default as AccordionHeader } from 'src/components/system_wide/header/accordion_header'
