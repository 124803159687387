import React from 'react'

class CategoryItem extends React.Component {
  static defaultProps = {
    category: {},
    onChangeCategory: () => {},
  }
  render() {
    let { name, medias } = this.props.category
    let img_path = '/storage/' + medias?.[0]?.full
    return (
      <>
        <style jsx="true">{`
          .list-group-item {
            background-color: black !important;
            margin-bottom: 10px !important;
          }

          .bg-sub-image {
            filter: blur(8px) !important;
            -webkit-filter: blur(8px) !important;
            height: 25vh !important;
            width: 100% !important;
            /* Center and scale the image nicely */
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
          }

          .logo-image {
            max-height: 22vh;
          }
          .texture-logo {
            background-image: url('../../../public/texture-back-logo.jpg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }

          .bg-top-image {
            height: 22vh;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
              5px 5px 15px 5px rgba(0, 0, 0, 0);
            box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
          }

          .name-category-box {
            box-shadow: 1px -72px 21px -18px rgba(0, 0, 0, 0.5) inset;
            -webkit-box-shadow: 1px -72px 21px -18px rgba(0, 0, 0, 0.5) inset;
            -moz-box-shadow: 1px -72px 21px -18px rgba(0, 0, 0, 0.5) inset;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            z-index: 999999999999999;
          }
          .name-category {
            color: white;
            position: absolute;
            bottom: 8px;
            left: 8px;
            font-size: 18px;
          }

          .vm-categories-box {
            overflow: auto;
          }
        `}</style>
        <div className="list-group-item" onClick={this.props.onChangeCategory(this.props.category)}>
          <div
            className="bg-sub-image"
            style={{
              backgroundImage: `url(${img_path})`,
            }}></div>
          <div
            style={{ position: 'absolute', zIndex: '9999999999', top: 0, width: '100%' }}
            className="row h-100 mx-auto">
            <div className="col-12 my-auto text-center">
              <div className="bg-top-image" style={{ backgroundImage: `url(${img_path})` }}></div>
            </div>
          </div>
          <div className="name-category-box">
            <div className="row">
              <div className="col-12">
                <strong className="name-category">{name}</strong>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default CategoryItem
