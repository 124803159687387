import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  products: [],
  categories: [],
  ingredients: [],
  tags: [],
  show_form: false,
  current_product: {
    id: null,
    name: '',
    description: '',
    price: 0,
    category_id: '',
    order_position: '',
    medias: [],
    ingredients: [],
    tags: [],
  },
}

export const getProducts = createAsyncThunk(
  'products/getProducts',
  async ({ tenant, offset, limit, sortData, filter, category_id }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().products
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData
      let response = await axios({
        url: `/api/products/${tenant}`,
        params: { offset, limit, sort_column, sort_direction, filter, category_id },
      })
      let { total, products } = response.data
      return { total, products, reset: offset === 0 }
    } catch (err) {
      error('Impossible recuperare i ruoli')
      return thunkApi.rejectWithValue()
    }
  }
)

export const getAllTags = createAsyncThunk('products/getAllTags', async ({ tenant }, thunkApi) => {
  try {
    let response = await axios({ url: `/api/tags/${tenant}/all` })
    return response.data
  } catch (err) {
    error('Impossible recuperare i ruoli')
    return thunkApi.rejectWithValue()
  }
})

export const getAllIngredients = createAsyncThunk('products/getAllIngredients', async ({ tenant }, thunkApi) => {
  try {
    let response = await axios({ url: `/api/ingredients/${tenant}/all` })
    return response.data
  } catch (err) {
    error('Impossible recuperare i ruoli')
    return thunkApi.rejectWithValue()
  }
})

export const getAllCategories = createAsyncThunk('products/getAllCategories', async ({ tenant }, thunkApi) => {
  try {
    let response = await axios({ url: `/api/categories/${tenant}/all`, method: 'GET' })
    return response.data
  } catch (err) {
    error('Impossible recuperare i ruoli')
    return thunkApi.rejectWithValue()
  }
})

export const createProduct = createAsyncThunk('products/createProduct', async ({ tenant, product }, thunkApi) => {
  try {
    let { name, description, price, category_id, ingredients, tags } = product
    let has_new_ingredients = ingredients.some(i => i?.__isNew__ === true)
    let has_new_tags = tags.some(i => i?.__isNew__ === true)
    await axios({
      url: `/api/product/${tenant}`,
      method: 'post',
      data: { name, description, price, category_id, ingredients, tags },
    })
    success('Ruolo creato con successo')
    thunkApi.dispatch(resetCurrentProduct())
    if (has_new_ingredients === true) {
      thunkApi.dispatch(getAllIngredients({ tenant }))
    }
    if (has_new_tags === true) {
      thunkApi.dispatch(getAllTags({ tenant }))
    }
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile creare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const updateProduct = createAsyncThunk('products/updateProduct', async ({ tenant, product }, thunkApi) => {
  try {
    let { id, name, description, price, category_id, ingredients, tags } = product
    await axios({
      url: `/api/product/${tenant}/${id}`,
      method: 'patch',
      data: { id, name, description, price, category_id, ingredients, tags },
    })
    success('Ruolo aggiornato con successo')
    thunkApi.dispatch(resetCurrentProduct())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile aggiornare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const changePosition = createAsyncThunk(
  'products/changePosition',
  async ({ tenant, product_id, category_id, direction }, thunkApi) => {
    try {
      await axios({
        url: `/api/product/${tenant}/${product_id}/${category_id}/${direction}`,
        method: 'GET',
      })
      success('Ruolo aggiornato con successo')
      thunkApi.dispatch(resetCurrentProduct())
    } catch (err) {
      error('Impossibile aggiornare ruolo')
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteProduct = createAsyncThunk('products/deleteProduct', async ({ tenant, id }, thunkApi) => {
  try {
    await axios({ url: `/api/product/${tenant}/${id}`, method: 'delete' })
    success('Ruolo eliminato con successo')
    thunkApi.dispatch(resetCurrentProduct())
  } catch (err) {
    error('Impossibile eliminare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const productsSlice = createSlice({
  name: 'products',
  initialState,

  reducers: {
    setCurrentProduct: (state, action) => {
      let { id, name, description, price, category_id, medias, ingredients, tags } = action.payload
      state.current_product.id = id
      state.current_product.name = name
      state.current_product.description = description
      state.current_product.price = price
      state.current_product.category_id = category_id
      state.current_product.medias = medias
      state.current_product.ingredients = ingredients
      state.current_product.tags = tags
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentProduct: state => {
      state.current_product = initialState.current_product
    },
    resetProducts: () => initialState,
  },

  extraReducers: {
    [getProducts.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, products, reset } = action.payload
      state.total = total
      state.products = reset ? [...products] : [...state.products, ...products]
    },
    [getProducts.rejected]: state => {
      state.total = initialState.total
      state.products = initialState.products
    },
    [getAllCategories.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { categories } = action.payload
      state.categories = categories
    },
    [getAllCategories.rejected]: state => {
      state.categories = initialState.categories
    },
    [getAllIngredients.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { ingredients } = action.payload
      state.ingredients = ingredients
    },
    [getAllIngredients.rejected]: state => {
      state.ingredients = initialState.ingredients
    },
    [getAllTags.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { tags } = action.payload
      state.tags = tags
    },
    [getAllTags.rejected]: state => {
      state.tags = initialState.tags
    },
  },
})

export const { setCurrentProduct, resetCurrentProduct, toggleShowForm, resetProducts } = productsSlice.actions
export default productsSlice.reducer
