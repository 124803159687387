import React from 'react'
import { Row, Col } from 'react-bootstrap'
import StoreLogo from './store_logo'
import CategoryItem from './category_item'
import colorContrast from 'color-contrast'

class CategoryMenu extends React.Component {
  static defaultProps = {
    menu: {},
    onChangeCategory: () => {},
  }

  render() {
    let { categories, color_background, color_menu, medias } = this.props.menu
    let logo_path = '/storage/' + medias?.[0]?.full
    let menu_text_color = colorContrast(color_menu, '#000') > colorContrast(color_menu, '#fff') ? 'black' : 'white'
    return (
      <Row className="d-flex flex-column flex-fill align-items-center px-0 gx-0">
        <Col sm="12" md="6" xxl="4" className="p-0">
          <StoreLogo logo_path={logo_path} bg_color={color_background} />
        </Col>
        <Col sm="12" md="6" xxl="4" className="p-0">
          <div className="text-center p-2" style={{ backgroundColor: color_menu }}>
            <span className="fs-20" style={{ color: menu_text_color }}>
              MENU
            </span>
          </div>
        </Col>
        <Col sm="12" md="6" xxl="4" className="p-0 vm-categories-box">
          {categories.map(c => {
            return <CategoryItem key={c.id} category={c} onChangeCategory={this.props.onChangeCategory} />
          })}
        </Col>
      </Row>
    )
  }
}

export default CategoryMenu
