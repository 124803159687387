import React from 'react'

import withJWTSession from 'src/enhancers/jwt_session'
import LoginForm from 'src/components/system_wide/login_form'

class LoginPage extends React.Component {
  render() {
    return <LoginForm redirectUrl="/admin" />
  }
}

export default withJWTSession({ valid: '/admin' })(LoginPage)
