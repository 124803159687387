import React from 'react'
import { Helmet } from 'react-helmet-async'
import { withRouter } from 'src/lib/router/with_router'
import { connect } from 'react-redux'

import { toggleShowForgotPassword } from 'src/store/login'
import LoginVariant from 'src/components/system_wide/login_form/login_variant'
import VerifyEmailVariant from 'src/components/system_wide/login_form/verify_email_variant'
import ForgotPasswordVariant from 'src/components/system_wide/login_form/forgot_password_variant'
import UpdatePasswordVariant from 'src/components/system_wide/login_form/update_password_variant'
import styles from 'src/components/system_wide/login_form/login_form.module.css'
import { resetLoginData } from 'src/store/login'

const mstp = state => {
  return { loginData: state.loginData, userInfo: state.userInfo }
}

class Login extends React.Component {
  static defaultProps = {
    redirectUrl: '/',
    maintenanceMode: false,
  }

  handleForgotPassword = e => {
    e.preventDefault()
    this.props.dispatch(toggleShowForgotPassword())
  }

  componentDidUpdate() {
    let { show_verify_email, show_update_password } = this.props.loginData
    let hasRequiredActions = show_verify_email || show_update_password
    if (!hasRequiredActions && this.props.userInfo.username) {
      return this.props.router.navigate(this.props.redirectUrl, { replace: true })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetLoginData())
  }

  render() {
    let tenant = this.props.router.location.pathname.split('/')[1]
    let { loginData, maintenanceMode } = this.props
    let { show_verify_email, show_update_password, show_forgot_password } = loginData
    let variant = ''
    switch (true) {
      case show_verify_email === true:
        variant = 'showVerifyEmail'
        break
      case show_update_password === false && show_forgot_password === false:
        variant = 'showLogin'
        break
      case show_update_password === false && show_forgot_password === true:
        variant = 'showForgotPwd'
        break
      case show_update_password === true && show_forgot_password === false:
        variant = 'showUpdatePwd'
        break
      default:
        break
    }

    return (
      <>
        <Helmet>
          <title>Area riservata</title>
          <style jsx="true">
            {`
              body {
                background-image: url(/bg2.jpg) !important;
                background-size: cover !important;
                background-position-y: 75%;
              }

              @media (min-width: 1080px), (min-height: 1000px) {
                body {
                  background-size: cover;
                }
              }
            `}
          </style>
        </Helmet>
        <div className={styles.container}>
          {variant === 'showLogin' && (
            <LoginVariant
              tenant={tenant}
              loginData={this.props.loginData}
              dispatch={this.props.dispatch}
              handleForgotPassword={this.handleForgotPassword}
              maintenanceMode={maintenanceMode}
            />
          )}
          {variant === 'showVerifyEmail' && (
            <VerifyEmailVariant tenant={tenant} loginData={this.props.loginData} dispatch={this.props.dispatch} />
          )}
          {variant === 'showForgotPwd' && (
            <ForgotPasswordVariant
              tenant={tenant}
              loginData={this.props.loginData}
              dispatch={this.props.dispatch}
              handleForgotPassword={this.handleForgotPassword}
            />
          )}
          {variant === 'showUpdatePwd' && (
            <UpdatePasswordVariant
              title="Update password"
              tenant={tenant}
              loginData={this.props.loginData}
              dispatch={this.props.dispatch}
            />
          )}
        </div>
      </>
    )
  }
}

export default connect(mstp)(withRouter(Login))
