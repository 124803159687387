/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'src/lib/router/with_router'
import { Modal, Button, Form, Alert } from 'react-bootstrap'

import { deleteTenant } from 'src/store/store_manager'
import ResponsiveScroller from 'src/components/system_wide/responsive_scroller'

const mstp = state => ({ tenant_name: state.storeManager.current_tenant.name })

class RemoveTenant extends React.Component {
  state = {
    show_modal: false,
    is_pending: false,
    confirm_text: '',
  }

  onProceed = () => {
    this.setState({ show_modal: true })
  }

  onCancel = () => {
    this.setState({ show_modal: false, confirm_text: '' })
  }

  onChangeConfirmText = e => {
    this.setState({ confirm_text: e.target.value })
  }

  onSuccess = () => {
    this.props.router.navigate('/admin', { replace: true })
  }

  onConfirmDelete = async () => {
    this.setState({ is_pending: true })
    let { tenant_name } = this.props
    await this.props.dispatch(deleteTenant({ name: tenant_name, onSuccess: this.onSuccess }))
    this.setState({ is_pending: false })
  }

  render() {
    if (!this.props.tenant_name) {
      return (
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
          <Alert variant="info">Seleziona punto vendita</Alert>
        </div>
      )
    }
    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
        <div className="d-lg-none border-bottom mb-3">
          <h4 className="text-primary">Elimina punto vendita</h4>
        </div>
        <ResponsiveScroller>
          <div className="container justify-content-center">
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading mb-4">Attenzione</h4>
              <p>
                L'eliminazione del punto vendita è un operazione irreversibile e comporta la cancellazione di tutti i
                dati memorizzati.
              </p>
              <hr />
              <p className="mb-0">Click su "Elimina" per continuare</p>
              <p className="text-center mt-4">
                <Button onClick={this.onProceed} variant="danger">
                  Elimina
                </Button>
              </p>
            </div>
          </div>
        </ResponsiveScroller>

        <Modal backdrop="static" centered show={this.state.show_modal}>
          <Modal.Header>
            <Modal.Title>Elimina punto vendita</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere all'eliminazione del punto vendita digitare <b>"Elimina"</b> nell'area di testo e cliccare
              sul bottone "Elimina".
            </p>
            <Form.Control
              type="text"
              value={this.state.confirm_text}
              onChange={this.onChangeConfirmText}
              disabled={this.state.is_pending}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancel} variant="secondary" disabled={this.state.is_pending}>
              Annulla
            </Button>
            <Button
              onClick={this.onConfirmDelete}
              disabled={this.state.confirm_text !== 'Elimina' || this.state.is_pending}
              variant="danger">
              {this.state.is_pending && (
                <>
                  <span className="spinner-border spinner-border-sm"></span>{' '}
                </>
              )}
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default connect(mstp)(withRouter(RemoveTenant))
