import React from 'react'
import { Button, Form } from 'react-bootstrap'

class IngredientForm extends React.Component {
  static defaultProps = {
    ingredient: {},
    onSave: () => {},
    closeForm: () => {},
  }

  state = {
    id: null,
    name: '',
  }
  constructor(props) {
    super(props)
    if (props.ingredient.id) {
      let { id, name } = props.ingredient
      this.state.id = id
      this.state.name = name
    }
  }

  handleChange = ({ target: { name, value } }) => {
    value = value !== '' ? value.charAt(0).toUpperCase() + value.slice(1) : value
    this.setState({ [name]: value })
  }

  handleConfirm = () => {
    this.props.onSave({ ...this.state })
  }

  isFormValid = () => {
    let { name } = this.state
    return name !== '' && this.props?.ingredient?.name !== name
  }

  render() {
    let title = this.state.id ? 'Modifica ingrediente' : 'Crea ingrediente'
    let { name } = this.state

    return (
      <>
        <h4 className="border-bottom text-primary">{title}</h4>
        <div className="flex-fill position-relative overflow-auto">
          <div className="w-100 ps-1 pe-4 position-absolute">
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Nome</Form.Label>
              <span> *</span>
              <Form.Control name="name" value={name} onChange={this.handleChange} />
            </Form.Group>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button className="float-end" variant="primary" disabled={!this.isFormValid()} onClick={this.handleConfirm}>
            Conferma
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default IngredientForm
