import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Alert } from 'react-bootstrap'

import ActionsDropdown from 'src/components/system_wide/actions_dropdown'
import SearchButton from 'src/components/system_wide/search_button'
import ResponsiveTable from 'src/components/system_wide/responsive_table'
import CategoryForm from 'src/components/admin/tenant_management/categories/category_form'
import ImageForm from 'src/components/system_wide/image_form'
import {
  setCurrentCategory,
  createCategory,
  updateCategory,
  deleteCategory,
  toggleShowForm,
  getCategories,
  resetCurrentCategory,
  resetCategories,
  changePosition,
} from 'src/store/categories'
import { getConstants } from 'src/lib/constants'

const mstp = state => {
  let { name: tenant_name } = state.storeManager.current_tenant
  let { total, categories, show_form, current_category } = state.categories
  return {
    tenant_name,
    total,
    categories,
    show_form,
    current_category,
  }
}

class Categories extends React.Component {
  state = {
    show_modal: false,
    filter: '',
    mode: 'edit',
  }

  tableRef = null
  position = getConstants().position

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        let { id, name, slug, order_position } = rowData
        this.setState({ mode: 'edit' })
        this.props.dispatch(
          setCurrentCategory({
            id,
            name,
            slug,
            order_position,
          })
        )
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-image',
      text: 'Carica immagine',
      onClick: rowData => {
        let { id, name, slug, order_position, medias } = rowData
        this.setState({ mode: 'image' })
        this.props.dispatch(
          setCurrentCategory({
            id,
            name,
            slug,
            order_position,
            medias,
          })
        )
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-arrow-up',
      text: 'Sposta su',
      onClick: async rowData => {
        let { id: category_id } = rowData
        await this.props.dispatch(changePosition({ tenant: this.props.tenant_name, category_id, direction: 'up' }))
        this.tableRef.current.refreshData()
      },
    },
    {
      icon: 'fa-arrow-down',
      text: 'Sposta giu',
      onClick: async rowData => {
        let { id: category_id } = rowData
        await this.props.dispatch(changePosition({ tenant: this.props.tenant_name, category_id, direction: 'down' }))
        this.tableRef.current.refreshData()
      },
    },
    {
      icon: 'fa-trash-alt',
      text: 'Elimina',
      onClick: rowData => {
        let { id, name, slug, order_position } = rowData
        this.props.dispatch(
          setCurrentCategory({
            id,
            name,
            slug,
            order_position,
          })
        )
        this.setState({ show_modal: true })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Nome',
      className: 'd-lg-block flex-fill',
      style: { width: '94%' },
      sortable: true,
      data: 'name',
    },
  ]

  onFilterChange = filter => this.setState({ filter })

  onChangeFilter = key => values =>
    this.setState({ [key]: values }, () => {
      this.tableRef.current.refreshData()
    })

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(
      getCategories({
        tenant: this.props.tenant_name,
        offset: index,
        limit: chunkSize,
        sortData,
        filter,
      })
    )
  }

  saveCategory = async category => {
    category.id
      ? await this.props.dispatch(updateCategory({ tenant: this.props.tenant_name, category }))
      : await this.props.dispatch(createCategory({ tenant: this.props.tenant_name, category }))
    this.tableRef.current.refreshData()
  }

  onToggleForm = () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentCategory())
      this.tableRef.current.refreshData()
    }
    this.props.dispatch(toggleShowForm())
  }

  onCancel = () => {
    this.props.dispatch(resetCurrentCategory())
    this.setState({ show_modal: false })
  }

  onConfirmDelete = async () => {
    await this.props.dispatch(deleteCategory({ tenant: this.props.tenant_name, id: this.props.current_category.id }))
    this.tableRef.current.refreshData()
    this.setState({ show_modal: false })
  }

  async componentDidMount() {
    // await this.props.dispatch(getAllCategoryTypes())
    // let filtered_types = this.props.category_types.map(t => t.id)
    // this.setState({ filtered_types, ready: true })
  }
  async componentDidUpdate(prevProps) {
    // if (prevProps.tenant_name !== this.props.tenant_name) {
    //   if (this.props.tenant_name) {
    //     this.props.dispatch(resetCategorys())
    //     await this.getData()
    //   }
    // }
  }

  componentWillUnmount() {
    this.props.dispatch(resetCategories())
  }

  render() {
    let { mode, filter, show_modal } = this.state
    let { tenant_name, categories, total, current_category, show_form } = this.props

    let menuContent = CategoryForm //CategoryForm
    let menuContentProps = {
      category: current_category,
      onSave: this.saveCategory,
      closeForm: this.onToggleForm,
    }
    if (mode === 'image') {
      menuContent = ImageForm
      menuContentProps = {
        title: 'Carica immagine di copertina della categoria',
        position: this.position.category,
        tenant: this.props.tenant_name,
        category_id: current_category.id,
        medias: current_category.medias,
        closeForm: this.onToggleForm,
      }
    }

    if (!tenant_name) {
      return (
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
          <Alert variant="info">Seleziona punto vendita</Alert>
        </div>
      )
    }
    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
        <div className="d-flex flex-column flex-fill">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <button
                  className="btn btn-primary btn-block w-100"
                  onClick={this.onToggleForm}
                  disabled={this.props.show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi categoria
                </button>
              </div>
              <div className="ms-sm-4 flex-fill flex-sm-grow-0 flex-sm-shrink-0 d-flex flex-wrap gap-2 filter-group">
                <style jsx="true">
                  {`
                    .filter-group :global(button) {
                      width: 100% !important;
                    }
                  `}
                </style>
              </div>
              <div className="flex-fill">
                <SearchButton
                  value={filter}
                  onChange={this.onFilterChange}
                  disabled={show_form}
                  className="float-md-end"
                />
              </div>
            </div>
          </div>

          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill"
            data={categories}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuWidth="40rem"
            menuContent={menuContent}
            menuContentProps={menuContentProps}
            rowClasses={rowData => (rowData.enabled ? '' : `text-muted`)}
            filter={filter}
          />
        </div>

        <Modal backdrop="static" centered show={show_modal} onHide={this.onCancel}>
          <Modal.Header>
            <Modal.Title>Elimina categoria</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere all'eliminazione della categoria <b>{current_category.name}</b>, cliccare sul bottone
              "Elimina"
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancel} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
export default connect(mstp)(Categories)
