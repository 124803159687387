import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'src/lib/router/with_router'
import { Row, Col, Offcanvas } from 'react-bootstrap'
import colorContrast from 'color-contrast'
import FilterForm from './filter_form'
import SearchButton from 'src/components/system_wide/search_button'
import ProductDetailModal from './product_detail_modal'
import styles from './products_menu.module.css'
import { getConstants } from 'src/lib/constants'
import { getIngredients, getTags } from 'src/store/digital_menu'

const mstp = state => {
  let { menu, ingredients, tags } = state.digitalMenu
  let tenant = menu.token_access
  return { tenant, ingredients, tags }
}

class ProductMenu extends React.Component {
  static defaultProps = {
    menu: {},
    current_category: {},
    onBackCategory: () => {},
  }
  state = {
    ready: false,
    show_filter_menu: false,
    filter: '',
    ingredient_selected: [],
    tag_selected: [],
    order_by: '',
    order_direction: '',
  }
  orderOptions = ['name', 'name', '']
  directionOption = ['ASC', 'DESC', '']
  position = getConstants().position
  productDetailModalRef = React.createRef()

  async componentDidMount() {
    let { tenant } = this.props
    await this.props.dispatch(getIngredients({ tenant }))
    await this.props.dispatch(getTags({ tenant }))
    this.setState({ ready: true })
  }

  toogleFilterMenu = () => {
    this.setState({ show_filter_menu: !this.state.show_filter_menu })
  }

  onFilterChange = filter => this.setState({ filter })

  onShowProductDetail = product => () => {
    this.productDetailModalRef.current.onShowProductDetail(product)
  }

  handleChangeFilter = ({ ingredient_selected, tag_selected }) => {
    this.setState({ tag_selected, ingredient_selected })
    this.toogleFilterMenu()
  }

  handleResetFilter = name => () => {
    this.setState({ [name]: [] })
  }

  handleOrderBy = () => {
    let { order_by, order_direction } = this.state
    if (order_by === '' && order_direction === '') {
      this.setState({ order_by: this.orderOptions[0], order_direction: this.directionOption[0] })
    } else if (order_by === 'name' && order_direction === 'ASC') {
      this.setState({ order_by: this.orderOptions[1], order_direction: this.directionOption[1] })
    } else if (order_by === 'name' && order_direction === 'DESC') {
      this.setState({ order_by: this.orderOptions[2], order_direction: this.directionOption[2] })
    }
  }

  evalFilter = products => {
    let { filter, ingredient_selected, tag_selected, order_by, order_direction } = this.state
    let regexp = new RegExp(`.*${filter ? filter : ''}.*`, 'i')
    let filtered_products = products.filter(p => regexp.test(p.name))
    if (ingredient_selected.length > 0) {
      filtered_products = filtered_products.filter(p => {
        return p.ingredients.some(i => ingredient_selected.includes(i.id) || ingredient_selected.length === 0)
      })
    }
    if (tag_selected.length > 0) {
      filtered_products = filtered_products.filter(p => {
        return p.tags.some(i => tag_selected.includes(i.id) || tag_selected.length === 0)
      })
    }
    if (order_by !== '' && order_direction !== '') {
      filtered_products.sort((a, b) => {
        if (order_direction === 'ASC') {
          return a[order_by].toLowerCase() < b[order_by].toLowerCase() ? -1 : 1
        } else {
          return a[order_by].toLowerCase() > b[order_by].toLowerCase() ? -1 : 1
        }
      })
    }
    return filtered_products
  }

  render() {
    let { menu, current_category, ingredients, tags } = this.props
    let { ready, filter, ingredient_selected, tag_selected, order_by, order_direction } = this.state
    let { color_menu, color_secondary } = menu
    if (!ready) return null
    let category_img =
      '/storage/' + current_category?.medias?.find(m => m.position === this.position.category)?.full ?? ''
    let product_default_img =
      '/storage/' + current_category?.medias?.find(m => m.position === this.position.default_prod_category)?.thumb ?? ''
    let menu_text_color = colorContrast(color_menu, '#000') > colorContrast(color_menu, '#fff') ? 'black' : 'white'
    let secondary_text_color =
      colorContrast(color_secondary, '#000') > colorContrast(color_secondary, '#fff') ? 'black' : 'white'
    let products = this.evalFilter(current_category?.products ?? [])
    let has_enabled_filter = ingredient_selected.length > 0 || tag_selected.length > 0

    return (
      <>
        <div className="d-flex flex-column flex-fill">
          <div
            style={{ backgroundColor: color_menu, color: menu_text_color }}
            className="d-flex flex-row align-items-center flex-fill p-3">
            <div>
              {has_enabled_filter === true && (
                <div
                  style={{
                    height: '10px',
                    width: '10px',
                    right: '34px',
                    top: '15px',
                    backgroundColor: 'red',
                    borderRadius: '100%',
                    position: 'absolute',
                  }}></div>
              )}
              <i className="fas fa-arrow-left fs-1" onClick={this.props.onBackCategory} />
              <span className="mb-0 fs-14 text-truncate">{current_category.name}</span>
            </div>
            <div className="ms-auto ">
              <i className="fa fa-bars fs-20" onClick={this.toogleFilterMenu} />
            </div>
          </div>
          <div
            style={{ backgroundColor: color_menu, color: menu_text_color }}
            className="d-flex align-items-center px-3 pb-3">
            <div className="flex-fill">
              <SearchButton value={filter} onChange={this.onFilterChange} />
            </div>
            <div className="mx-2 px-3" onClick={this.handleOrderBy}>
              {order_by === '' && order_direction === '' && <i className="fa-solid fa-sort"></i>}
              {order_by === 'name' && order_direction === 'ASC' && <i className="fa-solid fa-sort-up"></i>}
              {order_by === 'name' && order_direction === 'DESC' && <i className="fa-solid fa-sort-down"></i>}
            </div>
          </div>
          <Row className="d-flex flex-column align-items-center px-0 gx-0">
            <Col
              sm="12"
              md="12"
              xxl="8"
              className={`${styles.parallax} d-flex align-items-center px-0 gx-0`}
              style={{ backgroundImage: `url(${category_img})` }}>
              <div className="flex-fill text-center" style={{ background: '#00000080' }}>
                <span className="mb-0 fs-20 text-white">{current_category.name}</span>
                {ingredient_selected.length > 0 && (
                  <div className="text-white">
                    Ingredienti:{' '}
                    {ingredients
                      .filter(i => ingredient_selected.includes(i.id))
                      .map(i => i.name)
                      .join(', ')}
                    <i className="fa fa-circle-xmark ms-2" onClick={this.handleResetFilter('ingredient_selected')} />
                  </div>
                )}
                {tag_selected.length > 0 && (
                  <div className="text-white">
                    Tag:{' '}
                    {tags
                      .filter(t => tag_selected.includes(t.id))
                      .map(t => t.name)
                      .join(', ')}
                    <i className="fa fa-circle-xmark ms-2" onClick={this.handleResetFilter('tag_selected')} />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="d-flex flex-column align-items-center px-0 gx-0">
            <Col sm="12" md="12" xxl="8" className="p-2">
              {products.map(p => {
                let { medias, description, ingredients } = p
                let photo = medias?.[0]?.thumb ? '/storage/' + medias?.[0]?.thumb : product_default_img

                return (
                  <div key={p.id} className={`${styles.product_card} mb-2`} onClick={this.onShowProductDetail(p)}>
                    <Row className="gx-0">
                      <Col
                        xs="4"
                        sm="4"
                        className={`${styles.product_image} d-flex align-items-center`}
                        style={{
                          height: '12rem',
                          background: `url(${photo})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                        }}></Col>
                      <Col xs="8" sm="8">
                        <div className="d-flex flex-column flex-fill h-100" style={{ minHeight: '12rem' }}>
                          <div>
                            <strong className="fs-18 text-uppercase ms-2">{p.name}</strong>
                          </div>
                          <div className={styles.divider_2}>
                            <span style={{ backgroundColor: color_secondary }}></span>
                          </div>
                          <div className="flex-fill">
                            {description && (
                              <>
                                <div className="fw-bold ms-3">Descrizione:</div>
                                <div className={`ms-4 ${styles.line_clamp}`}>{p.description}</div>
                              </>
                            )}
                            {ingredients.length > 0 && (
                              <>
                                <div className="fw-bold ms-3">Ingredienti:</div>
                                <div className="ms-4">{p.ingredients.map(i => i.name).join(', ')}</div>
                              </>
                            )}
                          </div>
                          <div className="mt-auto text-end" style={{ borderBottom: `2px solid ${color_secondary}` }}>
                            <span className="mb-0" style={{ backgroundColor: color_secondary }}>
                              <strong
                                style={{
                                  padding: '4px',
                                  borderBottomRightRadius: '2px',
                                  backgroundColor: color_secondary,
                                  color: secondary_text_color,
                                }}
                                className="fs-16">
                                {p.price.toFixed(2)} €
                              </strong>
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </Col>
          </Row>
        </div>
        <Offcanvas
          className={styles.offcanvas_bg}
          show={this.state.show_filter_menu}
          placement="end"
          onHide={this.toogleFilterMenu}>
          <Offcanvas.Header closeButton className="border border-1">
            <Offcanvas.Title className="fs-20">Filtri</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <FilterForm
              onConfirm={this.handleChangeFilter}
              ingredient_selected={ingredient_selected}
              tag_selected={tag_selected}
              tags={tags}
              ingredients={ingredients}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <ProductDetailModal
          ref={this.productDetailModalRef}
          menu={this.props.menu}
          current_category={current_category}
        />
      </>
    )
  }
}

export default connect(mstp)(withRouter(ProductMenu))
