import React from 'react'
import { connect } from 'react-redux'

import withJWTSession from 'src/enhancers/jwt_session'
import AppLayout from 'src/components/app_layout'
import Sidebar from 'src/components/admin/tenant_management/sidebar'
import RemoveTenant from 'src/components/admin/tenant_management/remove_tenant'
import Header from 'src/components/admin/tenant_management/header'
import withCapabilities from 'src/enhancers/with_capabilities'

const mstp = state => ({ userInfo: state.userInfo })

class RemoveTenantPage extends React.Component {
  render() {
    let tenant_name = this.props.router.params.store_name
    return (
      <AppLayout containerSize={'fluid'} pageTitle="Rimuovi cliente" tenant={tenant_name}>
        <Header />
        <Sidebar />
        <RemoveTenant />
      </AppLayout>
    )
  }
}
export default withJWTSession({ invalid: '/admin/login' })(
  connect(mstp)(
    withCapabilities({
      some: ['bo_delete_tenant'],
    })(RemoveTenantPage)
  )
)
