import React from 'react'

class StoreLogo extends React.Component {
  static defaultProps = {
    logo_path: '',
    bg_color: '',
  }
  render() {
    return (
      // src="https://menu.nicelive.it/storage/2023/6/27/eeffa1a9abf22d2ae2e8d7125104c1a8.png"
      <div className="text-center m-auto texture-logo" style={{ background: this.props.bg_color }}>
        <img className="img-fluid" alt="logo" src={this.props.logo_path} style={{ maxHeight: '14rem' }} />
      </div>
    )
  }
}

export default StoreLogo
