import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'src/lib/router/with_router'

//import { error } from '../components/system_wide/notification'
import { updateUserinfo, resetUserinfo } from 'src/store/userinfo'
import axios from 'src/lib/axios'

const withJWTSession = route => Component => {
  return connect()(
    withRouter(
      class WithJWTSession extends React.Component {
        state = {
          ready: false,
          isError: false,
        }

        routeChangeComplete = async () => {
          let { navigate, location } = this.props.router

          try {
            let response = await axios.get('/api/refresh_token')
            let { id, username, email, first_name, last_name, tenant, caps, is_management_account, business_role_id } =
              response.data
            this.props.dispatch(
              updateUserinfo({
                id,
                username,
                email,
                first_name,
                last_name,
                tenant,
                is_management_account,
                caps,
                business_role_id,
              })
            )

            if (route?.valid) {
              if (location.pathname !== route.valid) {
                navigate(route.valid, { replace: true })
              }
            }
          } catch (e) {
            this.props.dispatch(resetUserinfo())
            //controllo presenza tenant
            if (e.response?.data?.message === 'tenant_not_found' && location.pathname !== '/admin/login') {
              navigate('/admin/login', { replace: true })
            }
            //  error(t('session_expired_error', 'Session expired')
            if (route?.invalid) {
              if (location.pathname !== route?.invalid) {
                navigate(route?.invalid, { replace: true })
              }
            } else {
              navigate('/admin/login', { replace: true })
            }
          }

          this.setState({ ready: true })
        }

        async componentDidMount() {
          await this.routeChangeComplete()
        }

        render() {
          //INJECT ORIGINAL PROPS + userData + router
          return this.state.ready ? <Component {...this.props} /> : null
        }
      }
    )
  )
}

export default withJWTSession
