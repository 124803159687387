import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Alert, Form } from 'react-bootstrap'

import StyledSelect from 'src/components/system_wide/styled_select'
import ActionsDropdown from 'src/components/system_wide/actions_dropdown'
import SearchButton from 'src/components/system_wide/search_button'
import ResponsiveTable from 'src/components/system_wide/responsive_table'
import ProductForm from 'src/components/admin/tenant_management/products/product_form'
import ImageForm from 'src/components/system_wide/image_form'
import UpoloadFormModal from 'src/components/system_wide/upload_form_modal'
import {
  setCurrentProduct,
  createProduct,
  updateProduct,
  deleteProduct,
  toggleShowForm,
  getProducts,
  resetCurrentProduct,
  resetProducts,
  changePosition,
  getAllCategories,
  getAllIngredients,
  getAllTags,
} from 'src/store/products'
import { getConstants } from 'src/lib/constants'

const mstp = state => {
  let { name: tenant_name, medias } = state.storeManager.current_tenant
  let { total, products, categories, tags, ingredients, show_form, current_product } = state.products
  return {
    tenant_name,
    total,
    products,
    tags,
    ingredients,
    show_form,
    current_product,
    categories,
    medias,
  }
}

class Products extends React.Component {
  state = {
    ready: false,
    mode: 'edit',
    show_modal: false,
    show_upload_modal: false,
    filter: '',
    category_id: null,
  }

  tableRef = React.createRef()
  position = getConstants().position

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        let { id, name, description, price, category_id, medias, ingredients, tags } = rowData
        this.setState({ mode: 'edit' })
        this.props.dispatch(
          setCurrentProduct({
            id,
            name,
            description,
            price,
            category_id,
            medias,
            ingredients,
            tags,
          })
        )
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-image',
      text: 'Carica immagine',
      onClick: rowData => {
        let { id, name, description, price, category_id, medias } = rowData
        this.setState({ mode: 'image' })
        this.props.dispatch(
          setCurrentProduct({
            id,
            name,
            description,
            price,
            category_id,
            medias,
          })
        )
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-arrow-up',
      text: 'Sposta su',
      onClick: async rowData => {
        let { id: product_id, category_id } = rowData
        await this.props.dispatch(
          changePosition({ tenant: this.props.tenant_name, product_id, category_id, direction: 'up' })
        )
        this.tableRef.current.refreshData()
      },
    },
    {
      icon: 'fa-arrow-down',
      text: 'Sposta giu',
      onClick: async rowData => {
        let { id: product_id, category_id } = rowData
        await this.props.dispatch(
          changePosition({ tenant: this.props.tenant_name, product_id, category_id, direction: 'down' })
        )
        this.tableRef.current.refreshData()
      },
    },
    {
      icon: 'fa-trash-alt',
      text: 'Elimina',
      onClick: rowData => {
        let { id, name, description, price, category_id } = rowData
        this.props.dispatch(
          setCurrentProduct({
            id,
            name,
            description,
            price,
            category_id,
          })
        )
        this.setState({ show_modal: true })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Nome',
      className: 'd-lg-block flex-fill',
      style: { width: '20%' },
      sortable: true,
      data: 'name',
    },
    {
      title: 'Descrizione',
      className: 'd-lg-block flex-fill',
      style: { width: '34%' },
      sortable: false,
      data: 'description',
    },
    {
      title: 'Prezzo',
      className: 'd-lg-block flex-fill',
      style: { width: '10%' },
      sortable: false,
      data: 'price',
    },
    {
      title: 'Categoria',
      className: 'd-lg-block flex-fill',
      style: { width: '15%' },
      sortable: false,
      data: ({ rowData }) => {
        return this.props.categories.find(c => c.id === rowData.category_id)?.name ?? ''
      },
    },
  ]

  onFilterChange = filter => {
    this.setState({ filter }, () => {
      this.tableRef.current.refreshData()
    })
  }

  getData = async (index = 0, chunkSize, sortData, filter) => {
    let { category_id } = this.state
    if (category_id !== null) {
      await this.props.dispatch(
        getProducts({
          tenant: this.props.tenant_name,
          offset: index,
          limit: chunkSize,
          sortData,
          filter,
          category_id,
        })
      )
    }
  }

  handleSelectChange = async (option, { name }) => {
    this.setState({ [name]: option?.value ? option.value : '' }, () => {
      this.tableRef.current.refreshData()
    })
  }

  saveProduct = async product => {
    product.id
      ? await this.props.dispatch(updateProduct({ tenant: this.props.tenant_name, product }))
      : await this.props.dispatch(createProduct({ tenant: this.props.tenant_name, product }))
    this.tableRef.current.refreshData()
  }

  onToggleForm = () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentProduct())
      this.tableRef.current.refreshData()
    }
    this.setState({ mode: 'edit' })
    this.props.dispatch(toggleShowForm())
  }

  onToogleUploadModal = () => {
    this.setState({ show_upload_modal: !this.state.show_upload_modal })
  }

  onCancel = () => {
    this.props.dispatch(resetCurrentProduct())
    this.setState({ show_modal: false })
  }

  onConfirmDelete = async () => {
    await this.props.dispatch(deleteProduct({ tenant: this.props.tenant_name, id: this.props.current_product.id }))
    this.tableRef.current.refreshData()
    this.setState({ show_modal: false })
  }

  async componentDidMount() {
    await this.props.dispatch(getAllCategories({ tenant: this.props.tenant_name }))
    await this.props.dispatch(getAllIngredients({ tenant: this.props.tenant_name }))
    await this.props.dispatch(getAllTags({ tenant: this.props.tenant_name }))
    this.setState({ ready: true })
  }

  componentWillUnmount() {
    this.props.dispatch(resetProducts())
  }

  render() {
    let { ready, mode, filter, show_upload_modal, show_modal, category_id } = this.state
    if (!ready) return null
    let { tenant_name, products, categories, tags, ingredients, total, current_product, show_form } = this.props
    let categoryOptions = categories.map(c => ({ label: c.name, value: c.id }))
    let menuContent = null //ProductForm
    let menuContentProps = {}
    if (mode === 'edit') {
      menuContent = ProductForm
      menuContentProps = {
        product: current_product,
        categoryOptions: categoryOptions,
        tags: tags,
        ingredients: ingredients,
        category_id: category_id,
        onSave: this.saveProduct,
        closeForm: this.onToggleForm,
      }
    }

    if (mode === 'image') {
      menuContent = ImageForm
      menuContentProps = {
        title: 'Carica immagine di copertina della categoria',
        position: this.position.product,
        tenant: this.props.tenant_name,
        category_id: category_id,
        product_id: current_product.id,
        medias: current_product.medias,
        closeForm: this.onToggleForm,
      }
    }

    if (!tenant_name) {
      return (
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
          <Alert variant="info">Seleziona punto vendita</Alert>
        </div>
      )
    }
    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
        <div className="d-flex flex-column flex-fill">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <div className="flex-fill">
                  <Form.Group>
                    <div style={{ width: '12rem' }}>
                      <StyledSelect
                        name="category_id"
                        className="ps-0"
                        isClearable
                        placeholder="Categoria"
                        value={categoryOptions.find(o => o.value === category_id)}
                        onChange={this.handleSelectChange}
                        options={categoryOptions}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0 d-flex flex-wrap gap-2 filter-group">
                <style jsx="true">
                  {`
                    .filter-group :global(button) {
                      width: 100% !important;
                    }
                  `}
                </style>
                <div className="flex-fill">
                  <Button onClick={this.onToggleForm} disabled={this.props.show_form || category_id === null}>
                    <i className="fas fa-plus fa-fw text-start" />
                    Aggiungi prodotto
                  </Button>
                </div>
                <div className="flex-fill">
                  <Button onClick={this.onToogleUploadModal} disabled={this.props.show_form || category_id === null}>
                    <i className="fas fa-image fa-fw text-start" />
                    Immagine di default
                  </Button>
                </div>
              </div>
              <div className="flex-fill">
                <SearchButton
                  value={filter}
                  onChange={this.onFilterChange}
                  disabled={show_form}
                  className="float-md-end"
                />
              </div>
            </div>
          </div>

          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill"
            data={products}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuWidth="40rem"
            menuContent={menuContent}
            menuContentProps={menuContentProps}
            rowClasses={rowData => (rowData.enabled ? '' : `text-muted`)}
            filter={filter}
          />
        </div>

        <Modal backdrop="static" centered show={show_modal} onHide={this.onCancel}>
          <Modal.Header>
            <Modal.Title>Elimina prodotto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere all'eliminazione della prodotto <b>{current_product.name}</b>, cliccare sul bottone
              "Elimina"
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancel} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
        {show_upload_modal === true && (
          <UpoloadFormModal
            show={show_upload_modal}
            tenant={this.props.tenant_name}
            position={this.position.default_prod_category}
            media_id={this.props.medias.find(m => m.position === this.position.default_prod_category)?.id ?? ''}
            category_id={category_id}
            preview={true}
            onCloseModal={this.onToogleUploadModal}
            onConfirm={this.onToogleUploadModal}
          />
        )}
      </div>
    )
  }
}
export default connect(mstp)(Products)
