import React from 'react'
import { Button, Form } from 'react-bootstrap'
import StyledSelect from 'src/components/system_wide/styled_select'

class FilterForm extends React.Component {
  static defaultProps = {
    onConfirm: () => {},
    ingredient_selected: [],
    tag_selected: [],
  }

  state = {
    ready: false,
    ingredient_selected: [],
    tag_selected: [],
  }
  componentDidMount() {
    let { ingredient_selected, tag_selected } = this.props
    this.setState({ ingredient_selected, tag_selected })
  }

  handleSelectChange = (option, { name }) => {
    let select = [...this.state[name]]
    select = option.map(o => o.value)
    this.setState({ [name]: select })
  }

  onConfirm = () => {
    let { ingredient_selected, tag_selected } = this.state
    this.props.onConfirm({ ingredient_selected, tag_selected })
  }

  render() {
    let { tags, ingredients } = this.props
    let { ingredient_selected, tag_selected } = this.state

    let tagOptions = (tags || []).map(t => ({ label: t.name, value: t.id }))
    let selectedtags = tagOptions.filter(d => tag_selected.includes(d.value))

    let ingredientOptions = (ingredients || []).map(i => ({ label: i.name, value: i.id }))
    let selectedIngredients = ingredientOptions.filter(d => ingredient_selected.includes(d.value))

    return (
      <>
        <Form.Group className="mb-3 fs-5">
          <Form.Label>Ingredienti</Form.Label>
          <StyledSelect
            name="ingredient_selected"
            isClearable
            placeholder="Ingredienti"
            value={selectedIngredients}
            isMulti={true}
            onChange={this.handleSelectChange}
            options={ingredientOptions}
          />
        </Form.Group>
        <Form.Group className="mb-3 fs-5">
          <Form.Label>Tag</Form.Label>
          <StyledSelect
            name="tag_selected"
            isClearable
            placeholder="Tags"
            isMulti={true}
            value={selectedtags}
            onChange={this.handleSelectChange}
            options={tagOptions}
          />
        </Form.Group>
        <div className="text-center">
          <Button className="bg-dark text-white fs-5" onClick={this.onConfirm}>
            Applica
          </Button>
        </div>
      </>
    )
  }
}

export default FilterForm
