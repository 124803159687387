import { configureStore } from '@reduxjs/toolkit'

import userInfoReducer from 'src/store/userinfo'
import loginReducer from 'src/store/login'
import storeManagerReducer from 'src/store/store_manager'
import usersReducer from 'src/store/users'
import documentsReducer from 'src/store/documents'
import rolesManagerReducer from 'src/store/roles_manager'
import categoriesReducer from 'src/store/categories'
import ingredientsReducer from 'src/store/ingredients'
import tagsReducer from 'src/store/tags'
import productsReducer from 'src/store/products'
import digitalMenuReducer from 'src/store/digital_menu'

const addGlobalResetEnhancer = createStore => (reducer, initialState, enhancer) => {
  const resettableReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
      return reducer(undefined, action)
    } else {
      return reducer(state, action)
    }
  }
  return createStore(resettableReducer, initialState, enhancer)
}

export function makeStore() {
  return configureStore({
    reducer: {
      userInfo: userInfoReducer,
      loginData: loginReducer,
      storeManager: storeManagerReducer,
      users: usersReducer,
      rolesManager: rolesManagerReducer,
      documents: documentsReducer,
      categories: categoriesReducer,
      ingredients: ingredientsReducer,
      tags: tagsReducer,
      products: productsReducer,
      digitalMenu: digitalMenuReducer,
    },

    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [addGlobalResetEnhancer],
  })
}

const store = makeStore()
export default store

export const resetStore = () => store.dispatch({ type: 'RESET_STORE' })
