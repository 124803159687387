import React from 'react'
import { Modal } from 'react-bootstrap'
import colorContrast from 'color-contrast'
import { getConstants } from 'src/lib/constants'
import ImageViewer from 'react-simple-image-viewer'
import styles from './products_menu.module.css'

class ProductDetailModal extends React.Component {
  static defaultProps = {
    menu: {},
    current_category: {},
  }

  state = {
    show: false,
    product: {},
    show_full_image: false,
  }

  position = getConstants().position

  onShowProductDetail = product => {
    this.setState({ show: true, product })
  }
  toogleOpenImageViewer = () => {
    this.setState({ show_full_image: !this.state.show_full_image })
  }

  onClose = () => {
    this.setState({ show: false })
  }

  render() {
    let { show, product, show_full_image } = this.state
    let { menu, current_category } = this.props
    let { name, medias, description = '', ingredients = [], tags = [] } = product
    let { color_menu, color_secondary } = menu
    let menu_text_color = colorContrast(color_menu, '#000') > colorContrast(color_menu, '#fff') ? 'black' : 'white'
    let secondary_text_color =
      colorContrast(color_secondary, '#000') > colorContrast(color_secondary, '#fff') ? 'black' : 'white'
    let default_img_thumb =
      '/storage/' + current_category?.medias?.find(m => m.position === this.position.default_prod_category)?.thumb ?? ''
    let default_img_full =
      '/storage/' + current_category?.medias?.find(m => m.position === this.position.default_prod_category)?.full ?? ''
    let photo_thumb = medias?.[0]?.thumb ? '/storage/' + medias?.[0]?.thumb : default_img_thumb
    let photo_full = medias?.[0]?.thumb ? '/storage/' + medias?.[0]?.full : default_img_full
    return (
      <>
        <Modal backdrop="static" centered show={show} onHide={this.onClose}>
          <style jsx="true">{`
            .btn-close {
              background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='${menu_text_color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !important;
            }
            .modal-body::before {
              content: '';
              position: absolute;
              background-color: transparent;
              bottom: 60px;
              height: 54px;
              width: 100%;
              border-bottom-right-radius: 100%;
              box-shadow: 0 58px 0 0 white;
            }
            .react-simple-image-viewer__modal {
              z-index: 99999;
            }
          `}</style>
          <Modal.Header closeButton className="rounded-0" style={{ background: color_menu, color: menu_text_color }}>
            <Modal.Title className="text-uppercase">{name}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div style={{ backgroundColor: color_secondary + '3b' }} className="row g-0">
              <div className="col-12">
                <div className="row">
                  <div
                    style={{ position: 'relative' }}
                    className="col-auto m-auto"
                    onClick={this.toogleOpenImageViewer}>
                    <img
                      className="img-fluid"
                      src={photo_thumb}
                      alt="immagine prodotto"
                      style={{ maxHeight: '200px' }}
                    />
                    <i
                      style={{ backgroundColor: color_secondary, color: secondary_text_color }}
                      className={`fas fa-search-plus ${styles.icon_zoom_image}`}></i>
                  </div>
                </div>
                <div style={{ padding: '3px 5px 3px 5px' }} className="col-12 text-start">
                  {tags?.map(t => {
                    return (
                      <span
                        key={t.id}
                        style={{ backgroundColor: color_menu, color: menu_text_color }}
                        className="badge me-2">
                        {t.name}
                      </span>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="row p-3 g-0">
              <div className="col-12">
                <div className="row product-info-modal">
                  <div className="col-12 mt-2">
                    <h3>INGREDIENTI</h3>
                    <p> {ingredients?.map(i => i.name).join(', ')}</p>
                  </div>
                  <hr />
                  <div className="col-12 " style={{ maxHeight: '18rem', overflowY: 'auto' }}>
                    <h6>Ulteriori informazioni</h6>
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: '55px', backgroundColor: color_secondary }} className="row g-0">
              <div style={{ color: secondary_text_color }} className="col-12 text-end my-auto mb-0">
                <strong className="fs-15 pe-1">{product?.price?.toFixed(2)} €</strong>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {show_full_image === true && (
          <ImageViewer
            src={[photo_full]}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={this.toogleOpenImageViewer}
          />
        )}
      </>
    )
  }
}

export default ProductDetailModal
