import React from 'react'
import { Button, Form } from 'react-bootstrap'
import UpoloadForm from 'src/components/system_wide/upload_form'

class ImageForm extends React.Component {
  static defaultProps = {
    title: '',
    position: '',
    tenant: '',
    type: '',
    category_id: '',
    product_id: '',
    medias: [],
    closeForm: () => {},
  }

  render() {
    let { tenant, title, position, category_id, product_id, medias } = this.props
    let has_media = medias?.[0]?.full ?? false
    let image_path = has_media !== false ? `/storage/${medias?.[0]?.full}` : ''
    return (
      <>
        <h4 className="border-bottom text-primary">Carica immagine</h4>
        <div className="flex-fill position-relative overflow-auto">
          <div className="w-100 ps-1 pe-4 position-absolute">
            <Form.Group className="mb-3">
              <Form.Label as="h5">{title}</Form.Label>
              <UpoloadForm
                tenant={tenant}
                position={position}
                onConfirm={this.props.closeForm}
                media_id={has_media !== false ? medias[0].id : ''}
                category_id={category_id}
                product_id={product_id}
                preview={true}
              />
            </Form.Group>
            {has_media !== false && (
              <div>
                <Form.Label as="h5">Immagine salvata</Form.Label>
                <div className="my-3">
                  <img src={image_path} alt="preview logo" style={{ maxWidth: '20rem' }} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="border-top pt-2">
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default ImageForm
