import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Alert } from 'react-bootstrap'

import ActionsDropdown from 'src/components/system_wide/actions_dropdown'
import SearchButton from 'src/components/system_wide/search_button'
import ResponsiveTable from 'src/components/system_wide/responsive_table'
import IngredientForm from 'src/components/admin/tenant_management/ingredients/ingredient_form'
import {
  setCurrentIngredient,
  createIngredient,
  updateIngredient,
  deleteIngredient,
  toggleShowForm,
  getIngredients,
  resetCurrentIngredient,
  resetIngredients,
} from 'src/store/ingredients'

const mstp = state => {
  let { name: tenant_name } = state.storeManager.current_tenant
  let { total, ingredients, show_form, current_ingredient } = state.ingredients
  return {
    tenant_name,
    total,
    ingredients,
    show_form,
    current_ingredient,
  }
}

class Ingredients extends React.Component {
  state = {
    show_modal: false,
    filter: '',
    mode: 'edit',
  }

  tableRef = null

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        let { id, name } = rowData
        this.setState({ mode: 'edit' })
        this.props.dispatch(
          setCurrentIngredient({
            id,
            name,
          })
        )
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-trash-alt',
      text: 'Elimina',
      onClick: rowData => {
        let { id, name } = rowData
        this.props.dispatch(
          setCurrentIngredient({
            id,
            name,
          })
        )
        this.setState({ show_modal: true })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '3rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Nome',
      className: 'd-lg-block flex-fill',
      style: { width: '15%' },
      sortable: true,
      data: 'name',
    },
  ]

  onFilterChange = filter => this.setState({ filter })

  onChangeFilter = key => values =>
    this.setState({ [key]: values }, () => {
      this.tableRef.current.refreshData()
    })

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(
      getIngredients({
        tenant: this.props.tenant_name,
        offset: index,
        limit: chunkSize,
        sortData,
        filter,
      })
    )
  }

  saveIngredient = async ingredient => {
    ingredient.id
      ? await this.props.dispatch(updateIngredient({ tenant: this.props.tenant_name, ingredient }))
      : await this.props.dispatch(createIngredient({ tenant: this.props.tenant_name, ingredient }))
    this.tableRef.current.refreshData()
  }

  onToggleForm = () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentIngredient())
      this.tableRef.current.refreshData()
    }
    this.props.dispatch(toggleShowForm())
  }

  onCancel = () => {
    this.props.dispatch(resetCurrentIngredient())
    this.setState({ show_modal: false })
  }

  onConfirmDelete = async () => {
    await this.props.dispatch(
      deleteIngredient({ tenant: this.props.tenant_name, id: this.props.current_ingredient.id })
    )
    this.tableRef.current.refreshData()
    this.setState({ show_modal: false })
  }

  componentWillUnmount() {
    this.props.dispatch(resetIngredients())
  }

  render() {
    let { filter, show_modal } = this.state
    let { tenant_name, ingredients, total, current_ingredient, show_form } = this.props

    let menuContent = IngredientForm //IngredientForm
    let menuContentProps = {
      ingredient: current_ingredient,
      onSave: this.saveIngredient,
      closeForm: this.onToggleForm,
    }

    if (!tenant_name) {
      return (
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
          <Alert variant="info">Seleziona punto vendita</Alert>
        </div>
      )
    }
    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
        <div className="d-flex flex-column flex-fill">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <button
                  className="btn btn-primary btn-block w-100"
                  onClick={this.onToggleForm}
                  disabled={this.props.show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi ingrediente
                </button>
              </div>
              <div className="ms-sm-4 flex-fill flex-sm-grow-0 flex-sm-shrink-0 d-flex flex-wrap gap-2 filter-group">
                <style jsx="true">
                  {`
                    .filter-group :global(button) {
                      width: 100% !important;
                    }
                  `}
                </style>
              </div>
              <div className="flex-fill">
                <SearchButton
                  value={filter}
                  onChange={this.onFilterChange}
                  disabled={show_form}
                  className="float-md-end"
                />
              </div>
            </div>
          </div>

          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill"
            data={ingredients}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuWidth="40rem"
            menuContent={menuContent}
            menuContentProps={menuContentProps}
            rowClasses={rowData => (rowData.enabled ? '' : `text-muted`)}
            filter={filter}
          />
        </div>

        <Modal backdrop="static" centered show={show_modal} onHide={this.onCancel}>
          <Modal.Header>
            <Modal.Title>Elimina categoria</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere all'eliminazione della categoria <b>{current_ingredient?.name}</b>, cliccare sul bottone
              "Elimina"
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancel} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
export default connect(mstp)(Ingredients)
