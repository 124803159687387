import React from 'react'
import { Outlet } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { withRouter } from 'src/lib/router/with_router'
import { ReactNotifications } from 'react-notifications-component'
import FooterMenu from 'src/components/system_wide/footer_menu'
import ThemeContext from 'src/components/system_wide/theme_context'

class MenuApp extends React.Component {
  state = { ready: false, tenant: '' }

  componentDidMount() {
    let { location, navigate } = this.props.router
    let tenant = location.pathname.split('/')?.[1] || ''
    this.setState({ ready: true })
    if (!location.pathname.split('/')?.[1]) return setTimeout(() => navigate(`/${tenant}`, { replace: true }))
  }

  componentDidCatch(error, info) {
    console.log('error: ', error)
    console.log('info: ', info)
  }

  render() {
    //linear-gradient(0deg, rgb(0 0 0 / 60%), rgb(3 3 3 / 60%)),
    return (
      <>
        <HelmetProvider>
          <ThemeContext.Provider value={this.theme}>
            {this.state.ready === true && (
              <>
                <style jsx="true">{`
                  .layout {
                    background: url(/texture-card-1.jpg);
                    background-size: cover;
                  }
                `}</style>
                <ReactNotifications />
                <main className={`layout`}>
                  <Outlet {...this.props} />
                </main>
                <FooterMenu containerSize={'fluid'} />
              </>
            )}
          </ThemeContext.Provider>
        </HelmetProvider>
      </>
    )
  }
}

export default withRouter(MenuApp)
