import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  tags: [],
  show_form: false,
  current_tag: {
    id: null,
    name: '',
  },
}

export const getTags = createAsyncThunk(
  'tags/getTags',
  async ({ tenant, offset, limit, sortData, filter }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().tags
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData
      let response = await axios({
        url: `/api/tags/${tenant}`,
        params: { offset, limit, sort_column, sort_direction, filter },
      })
      let { total, tags } = response.data
      return { total, tags, reset: offset === 0 }
    } catch (err) {
      error('Impossible recuperare i ruoli')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createTag = createAsyncThunk('tags/createTag', async ({ tenant, tag }, thunkApi) => {
  try {
    let { name } = tag
    await axios({
      url: `/api/tag/${tenant}`,
      method: 'post',
      data: { name },
    })
    success('Ruolo creato con successo')
    thunkApi.dispatch(resetCurrentTag())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile creare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const updateTag = createAsyncThunk('tags/updateTag', async ({ tenant, tag }, thunkApi) => {
  try {
    let { id, name } = tag
    await axios({
      url: `/api/tag/${tenant}/${id}`,
      method: 'patch',
      data: { id, name },
    })
    success('Ruolo aggiornato con successo')
    thunkApi.dispatch(resetCurrentTag())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile aggiornare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const deleteTag = createAsyncThunk('tags/deleteTag', async ({ tenant, id }, thunkApi) => {
  try {
    await axios({ url: `/api/tag/${tenant}/${id}`, method: 'delete' })
    success('Ruolo eliminato con successo')
    thunkApi.dispatch(resetCurrentTag())
  } catch (err) {
    error('Impossibile eliminare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,

  reducers: {
    setCurrentTag: (state, action) => {
      let { id, name } = action.payload
      state.current_tag.id = id
      state.current_tag.name = name
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentTag: state => {
      state.current_tag = initialState.current_tag
    },
    resetTags: () => initialState,
  },

  extraReducers: {
    [getTags.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, tags, reset } = action.payload
      state.total = total
      state.tags = reset ? [...tags] : [...state.tags, ...tags]
    },
    [getTags.rejected]: state => {
      state.total = initialState.total
      state.tags = initialState.tags
    },
  },
})

export const { setCurrentTag, resetCurrentTag, toggleShowForm, resetTags } = tagsSlice.actions
export default tagsSlice.reducer
