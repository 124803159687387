import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'src/lib/router/with_router'
import { Button, Form, Row, Col, Alert } from 'react-bootstrap'
import ResponsiveScroller from 'src/components/system_wide/responsive_scroller'
import { updateCurrentTenantSettings } from 'src/store/store_manager'
import UpoloadForm from 'src/components/system_wide/upload_form'
import { getConstants } from 'src/lib/constants'
const mstp = state => {
  let {
    id,
    name: tenant_name,
    color_menu,
    color_background,
    color_secondary,
    medias,
    created_at,
  } = state.storeManager.current_tenant
  return {
    id,
    tenant_name,
    color_menu,
    color_background,
    color_secondary,
    medias,
    created_at,
  }
}

class Preview extends React.Component {
  state = {
    name: '',
    color_menu: '',
    color_background: '',
    color_secondary: '',
    logo: '',
    remote_logo: '',
  }

  iframeRef = null
  position = getConstants().position

  constructor(props) {
    super(props)
    let { tenant_name, color_menu, color_background, color_secondary, medias } = props
    this.state.name = tenant_name
    this.state.color_menu = tenant_name
    this.state.color_menu = color_menu ?? ''
    this.state.color_background = color_background ?? ''
    this.state.color_secondary = color_secondary ?? ''
    this.state.remote_logo = medias?.[0]?.full ?? ''
    this.iframeRef = React.createRef()
  }

  async componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.tenant_name !== this.props.tenant_name) {
      let { tenant_name, color_menu, color_background, color_secondary } = this.props
      this.setState({ name: tenant_name, color_menu, color_background, color_secondary })
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  isChanged = () => {
    let { name, color_menu, color_background, color_secondary, logo } = this.state
    return (
      this.props.tenant_name !== name ||
      this.props.color_menu !== color_menu ||
      this.props.color_background !== color_background ||
      this.props.color_secondary !== color_secondary ||
      logo !== ''
    )
  }

  applyChanges = () => {
    this.props.dispatch(
      updateCurrentTenantSettings({
        tenant_name: this.props.tenant_name,
        ...this.state,
      })
    )
    this.iframeRef.current.src += ''
  }

  render() {
    let { name, color_menu, color_background, color_secondary } = this.state
    if (!this.props.tenant_name) {
      return (
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
          <Alert variant="info">Seleziona punto vendita</Alert>
        </div>
      )
    }
    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3">
        <div className="d-lg-none border-bottom mb-3">
          <h4 className="text-primary">Impostazioni generali</h4>
        </div>
        <ResponsiveScroller>
          <Form className="mb-2 p-2 pt-0" noValidate>
            <Row className="g-2">
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>Nome link</Form.Label>
                  <Form.Control name="name" value={name} onChange={this.handleChange} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Colore menu</Form.Label>
                  <Form.Control
                    type="color"
                    name="color_menu"
                    style={{ width: '10rem' }}
                    title="Color"
                    value={color_menu}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label as="h6">Colore sfondo</Form.Label>
                  <Form.Control
                    type="color"
                    name="color_background"
                    style={{ width: '10rem' }}
                    title="Color"
                    value={color_background}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label as="h6">Colore secondario</Form.Label>
                  <Form.Control
                    type="color"
                    name="color_secondary"
                    style={{ width: '10rem' }}
                    title="Color"
                    value={color_secondary}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                {/* <div className="d-flex align-items-center">
                  <div style={{ width: '7rem', height: '5rem' }}>
                    {logo !== '' && <img src={`${logo}`} className="img-fluid my-auto" />}
                    {logo === '' && remote_logo !== '' && (
                      <img src={`storage/public${remote_logo}`} className="img-fluid my-auto" />
                    )}
                  </div>
                  <div>
                    <Form.Group className="">
                      <Form.Label as="h5">Carica logo</Form.Label>
                      <Form.Control
                        isInvalid={this.state.error}
                        type="file"
                        style={{ maxWidth: '24rem' }}
                        onChange={this.onFileChange}
                      />
                      <Form.Control.Feedback type="invalid">{this.state.error}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div> */}

                <Form.Group className="mb-3">
                  <Form.Label as="h5">Carica logo</Form.Label>
                  <UpoloadForm
                    tenant={this.props.tenant_name}
                    position={this.position.logo}
                    onConfirm={this.handleUploadConfirm}
                  />
                </Form.Group>
              </Col>
              <Col md="8" className="m-auto text-center">
                <iframe
                  ref={this.iframeRef}
                  title="preview"
                  className="p-3 mt-3"
                  style={{
                    height: 'calc(100vh - 305px)',
                    maxWidth: '30rem',
                    overflow: 'hidden',
                    border: '13px solid #ddd',
                    borderRadius: '25px',
                  }}
                  src={`/menu-digitale/${this.props.tenant_name}`}
                  width="100%"
                  height="100%"></iframe>
              </Col>
            </Row>
          </Form>
        </ResponsiveScroller>
        <div className="text-end pt-2 border-top">
          <Button variant="primary" disabled={!this.isChanged()} onClick={this.applyChanges}>
            Salva
          </Button>
        </div>
      </div>
    )
  }
}
export default connect(mstp)(withRouter(Preview))
