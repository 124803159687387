import * as React from 'react'
import { Card, Spinner } from 'react-bootstrap'
import { FileUploader } from 'react-drag-drop-files'

import styles from 'src/components/system_wide/file_dropzone.module.css'

class FileDropzone extends React.Component {
  static defaultProps = {
    multiple: false,
    dimmed: false,
    title: 'Importa file',
    progressingTitle: 'Operazione in corso...',
    description: "Trascinare il file in quest'area o fare clic per selezionarlo",
    accept: [],
    onDrop: () => {},
  }

  handleChange = file => {
    this.props.onDrop(file)
  }

  render() {
    let { dimmed, title, progressingTitle, description, accept } = this.props
    let types = accept.length ? { types: accept } : {}

    if (dimmed) {
      return (
        <Card className={styles.card_progressing}>
          <div className="p-2 text-center w-100">
            <div className={`rounded p-2 ${styles.bg_gray}`}>
              <h4>{progressingTitle}</h4>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Caricamento...</span>
              </Spinner>
            </div>
          </div>
        </Card>
      )
    } else {
      return (
        <FileUploader
          classes={styles.custom_dropzone}
          name="file-uploader"
          multiple={false}
          handleChange={this.handleChange}
          {...types}>
          <Card className={styles.card_progressing}>
            <div className="p-2 text-center w-100">
              <div className={`rounded p-2 ${styles.bg_gray}`}>
                {title}
                <br />
                <small>{description}</small>
              </div>
            </div>
          </Card>
        </FileUploader>
      )
    }
  }
}

export default FileDropzone
