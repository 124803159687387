import React from 'react'
import { Button, Form, Badge } from 'react-bootstrap'
import NumericInput from 'react-numeric-input'
import StyledSelect from 'src/components/system_wide/styled_select'
import hash from 'object-hash'

class ProductForm extends React.Component {
  static defaultProps = {
    product: {},
    ingredients: [],
    tags: [],
    categoryOptions: '',
    category_id: null,
    onSave: () => {},
    closeForm: () => {},
  }

  state = {
    id: null,
    name: '',
    description: '',
    price: 0,
    category_id: '',
    selected_ingredients: [],
    selected_tags: [],

    product_hash: '',
  }

  initialHash = ''

  constructor(props) {
    super(props)
    if (props.product.id) {
      let { id, name, description, price, category_id, ingredients, tags } = props.product
      this.state.id = id
      this.state.name = name ?? ''
      this.state.description = description ?? ''
      this.state.price = price ?? 0
      this.state.category_id = category_id ?? ''
      this.state.selected_ingredients = ingredients?.map(i => ({ label: i.name, value: i.id })) ?? []
      this.state.selected_tags = tags?.map(t => ({ label: t.name, value: t.id })) ?? []
    } else {
      this.state.category_id = this.props.category_id
    }
    let { product_hash, ...rest } = this.state
    this.initialHash = hash(rest)
    this.state.product_hash = this.initialHash
  }

  updateHash = () => {
    let { product_hash, ...rest } = this.state
    this.setState({ product_hash: hash(rest) })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  handleConfirm = () => {
    let { selected_ingredients: ingredients, selected_tags: tags, ...rest } = this.state
    this.props.onSave({ ...rest, ingredients, tags })
  }

  handleSelectChange = (option, { name }) => {
    let newState = {}
    if (['ingredients', 'tags'].includes(name)) {
      let selected = [...this.state[`selected_${name}`]]
      let isNew = option?.__isNew ?? false
      let isContains = selected.filter(i => i.value === option.value || i.label === option.label)?.length > 0 ?? false
      if (!isNew && !isContains) {
        option.label = option.label.charAt(0).toUpperCase() + option.label.slice(1)
        selected.push(option)
      } else if (isNew && !isContains) {
        selected.push(option)
      }
      newState[`selected_${name}`] = selected
    } else {
      newState['name'] = option ? option.value : ''
    }

    this.setState(newState, () => this.updateHash())
  }

  handleRemoveAttr = (name, option) => () => {
    let selected = [...this.state[`selected_${name}`]].filter(i => i.value !== option.value || i.label !== option.label)
    this.setState({ [`selected_${name}`]: selected }, () => this.updateHash())
  }

  onChangeNumericInput = (value, rawValue, { name }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  isFormValid = () => {
    return this.state.name !== '' && this.initialHash !== this.state.product_hash
  }

  render() {
    let { categoryOptions, ingredients, tags } = this.props
    let title = this.state.id ? 'Modifica prodotto' : 'Crea prodotto'
    let { name, description, price, category_id, selected_ingredients, selected_tags } = this.state
    let ingredientOptions = ingredients
      .filter(i => selected_ingredients.every(si => si.label !== i.name) || selected_ingredients.length === 0)
      .map(i => ({ label: i.name, value: i.id }))

    let tagOptions = tags
      .filter(i => selected_tags.every(si => si.label !== i.name) || selected_tags.length === 0)
      .map(i => ({ label: i.name, value: i.id }))

    return (
      <>
        <h4 className="border-bottom text-primary">{title}</h4>
        <div className="flex-fill position-relative overflow-auto">
          <div className="w-100 ps-1 pe-4 position-absolute">
            <Form.Group>
              <div style={{ width: '18rem' }}>
                <StyledSelect
                  name="category_id"
                  className="ps-0"
                  isClearable
                  placeholder="Categoria"
                  value={categoryOptions.find(o => o.value === category_id)}
                  onChange={this.handleSelectChange}
                  options={categoryOptions}
                />
              </div>
            </Form.Group>
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Nome</Form.Label>
              <span> *</span>
              <Form.Control name="name" value={name} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Descrizione</Form.Label>
              <Form.Control
                name="description"
                as="textarea"
                rows={2}
                value={description}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Prezzo</Form.Label>
              <div style={{ width: '10rem' }}>
                <NumericInput
                  className="form-control form-control-sm"
                  precision={2}
                  min={0}
                  step={0.5}
                  snap
                  inputMode="decimal"
                  strict
                  name="price"
                  value={price}
                  onChange={this.onChangeNumericInput}
                />
              </div>
            </Form.Group>
            <div className="mb-3">
              <h5>Ingredienti</h5>
              <p className="text-muted">Seleziona gli ingredienti che compongono il prodotto</p>
              <div className="d-flex gap-2 mb-3">
                <Form.Group>
                  <div style={{ width: '18rem' }}>
                    <StyledSelect
                      name="ingredients"
                      className="ps-0"
                      isClearable
                      placeholder="Ingredienti"
                      allowCreate={true}
                      onChange={this.handleSelectChange}
                      options={ingredientOptions}
                    />
                  </div>
                </Form.Group>
              </div>
              {selected_ingredients.map(i => {
                return (
                  <Badge key={i.value} className="ms-2 p-2">
                    {i.label}
                    <i className="fa fa-close ms-2" onClick={this.handleRemoveAttr('ingredients', i)} />
                  </Badge>
                )
              })}
            </div>
            <div className="mb-3">
              <h5>Tags</h5>
              <p className="text-muted">Seleziona i tag </p>
              <div className="d-flex gap-2 mb-3">
                <Form.Group>
                  <div style={{ width: '18rem' }}>
                    <StyledSelect
                      name="tags"
                      className="ps-0"
                      isClearable
                      placeholder="Tags"
                      allowCreate={true}
                      onChange={this.handleSelectChange}
                      options={tagOptions}
                    />
                  </div>
                </Form.Group>
              </div>
              {selected_tags.map(i => {
                return (
                  <Badge key={i.value} className="ms-2 p-2">
                    {i.label}
                    <i className="fa fa-close ms-2" onClick={this.handleRemoveAttr('tags', i)} />
                  </Badge>
                )
              })}
            </div>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button className="float-end" variant="primary" disabled={!this.isFormValid()} onClick={this.handleConfirm}>
            Conferma
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            Annulla
          </Button>
        </div>
      </>
    )
  }
}
export default ProductForm
