import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  categories: [],
  show_form: false,
  current_category: {
    id: null,
    name: '',
    slug: '',
    order_position: '',
    medias: [],
  },
}

export const getCategories = createAsyncThunk(
  'categories/getCategories',
  async ({ tenant, offset, limit, sortData, filter }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().categories
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData
      let response = await axios({
        url: `/api/categories/${tenant}`,
        params: { offset, limit, sort_column, sort_direction, filter },
      })
      let { total, categories } = response.data
      return { total, categories, reset: offset === 0 }
    } catch (err) {
      error('Impossible recuperare i ruoli')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createCategory = createAsyncThunk('categories/createCategory', async ({ tenant, category }, thunkApi) => {
  try {
    let { name, image } = category
    await axios({
      url: `/api/category/${tenant}`,
      method: 'post',
      data: { name, image },
    })
    success('Ruolo creato con successo')
    thunkApi.dispatch(resetCurrentCategory())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile creare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const updateCategory = createAsyncThunk('categories/updateCategory', async ({ tenant, category }, thunkApi) => {
  try {
    let { id, name, image } = category
    await axios({
      url: `/api/category/${tenant}/${id}`,
      method: 'patch',
      data: { id, name, image },
    })
    success('Ruolo aggiornato con successo')
    thunkApi.dispatch(resetCurrentCategory())
    thunkApi.dispatch(toggleShowForm())
  } catch (err) {
    error('Impossibile aggiornare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const changePosition = createAsyncThunk(
  'categories/changePosition',
  async ({ tenant, category_id, direction }, thunkApi) => {
    try {
      await axios({
        url: `/api/category/${tenant}/${category_id}/${direction}`,
        method: 'GET',
      })
      success('Ruolo aggiornato con successo')
      thunkApi.dispatch(resetCurrentCategory())
    } catch (err) {
      error('Impossibile aggiornare ruolo')
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteCategory = createAsyncThunk('categories/deleteCategory', async ({ tenant, id }, thunkApi) => {
  try {
    await axios({ url: `/api/category/${tenant}/${id}`, method: 'delete' })
    success('Ruolo eliminato con successo')
    thunkApi.dispatch(resetCurrentCategory())
  } catch (err) {
    error('Impossibile eliminare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,

  reducers: {
    setCurrentCategory: (state, action) => {
      let { id, name, slug, order_position, medias = [] } = action.payload
      state.current_category.id = id
      state.current_category.name = name
      state.current_category.slug = slug
      state.current_category.order_position = order_position
      state.current_category.medias = medias
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentCategory: state => {
      state.current_category = initialState.current_category
    },
    resetCategories: () => initialState,
  },

  extraReducers: {
    [getCategories.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, categories, reset } = action.payload
      state.total = total
      state.categories = reset ? [...categories] : [...state.categories, ...categories]
    },
    [getCategories.rejected]: state => {
      state.total = initialState.total
      state.categories = initialState.categories
    },
  },
})

export const { setCurrentCategory, resetCurrentCategory, toggleShowForm, resetCategories } = categoriesSlice.actions
export default categoriesSlice.reducer
