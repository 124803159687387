import React from 'react'
import { connect } from 'react-redux'

import withJWTSession from 'src/enhancers/jwt_session'
import Products from 'src/components/admin/tenant_management/products'
import AppLayout from 'src/components/app_layout'
import Sidebar from 'src/components/admin/tenant_management/sidebar'
import Header from 'src/components/admin/tenant_management/header'
import withCapabilities from 'src/enhancers/with_capabilities'

const mstp = state => ({ userInfo: state.userInfo })

class ProductPage extends React.Component {
  render() {
    let tenant_name = this.props.router.params.store_name
    return (
      <AppLayout containerSize={'fluid'} pageTitle="Prodotti" tenant={tenant_name}>
        <Header />
        <Sidebar />
        <Products />
      </AppLayout>
    )
  }
}
export default withJWTSession({ invalid: '/admin/login' })(
  connect(mstp)(
    withCapabilities({
      some: [],
    })(ProductPage)
  )
)
