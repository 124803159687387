import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { error, success } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  total: 0,
  ingredients: [],
  show_form: false,
  current_ingredient: {
    id: null,
    name: '',
  },
}

export const getIngredients = createAsyncThunk(
  'ingredients/getIngredients',
  async ({ tenant, offset, limit, sortData, filter }, thunkApi) => {
    try {
      let { total: currentTotal } = thunkApi.getState().ingredients
      if (offset > currentTotal) return

      let { sort_column, sort_direction } = sortData
      let response = await axios({
        url: `/api/ingredients/${tenant}`,
        params: { offset, limit, sort_column, sort_direction, filter },
      })
      let { total, ingredients } = response.data
      return { total, ingredients, reset: offset === 0 }
    } catch (err) {
      error('Impossible recuperare i ruoli')
      return thunkApi.rejectWithValue()
    }
  }
)

export const createIngredient = createAsyncThunk(
  'ingredients/createIngredient',
  async ({ tenant, ingredient }, thunkApi) => {
    try {
      let { name } = ingredient
      await axios({
        url: `/api/ingredient/${tenant}`,
        method: 'post',
        data: { name },
      })
      success('Ruolo creato con successo')
      thunkApi.dispatch(resetCurrentIngredient())
      thunkApi.dispatch(toggleShowForm())
    } catch (err) {
      error('Impossibile creare ruolo')
      return thunkApi.rejectWithValue()
    }
  }
)

export const updateIngredient = createAsyncThunk(
  'ingredients/updateIngredient',
  async ({ tenant, ingredient }, thunkApi) => {
    try {
      let { id, name } = ingredient
      await axios({
        url: `/api/ingredient/${tenant}/${id}`,
        method: 'patch',
        data: { id, name },
      })
      success('Ruolo aggiornato con successo')
      thunkApi.dispatch(resetCurrentIngredient())
      thunkApi.dispatch(toggleShowForm())
    } catch (err) {
      error('Impossibile aggiornare ruolo')
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteIngredient = createAsyncThunk('ingredients/deleteIngredient', async ({ tenant, id }, thunkApi) => {
  try {
    await axios({ url: `/api/ingredient/${tenant}/${id}`, method: 'delete' })
    success('Ruolo eliminato con successo')
    thunkApi.dispatch(resetCurrentIngredient())
  } catch (err) {
    error('Impossibile eliminare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const ingredientsSlice = createSlice({
  name: 'ingredients',
  initialState,

  reducers: {
    setCurrentIngredient: (state, action) => {
      let { id, name } = action.payload
      state.current_ingredient.id = id
      state.current_ingredient.name = name
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    resetCurrentIngredient: state => {
      state.current_ingredient = initialState.current_ingredient
    },
    resetIngredients: () => initialState,
  },

  extraReducers: {
    [getIngredients.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, ingredients, reset } = action.payload
      state.total = total
      state.ingredients = reset ? [...ingredients] : [...state.ingredients, ...ingredients]
    },
    [getIngredients.rejected]: state => {
      state.total = initialState.total
      state.ingredients = initialState.ingredients
    },
  },
})

export const { setCurrentIngredient, resetCurrentIngredient, toggleShowForm, resetIngredients } =
  ingredientsSlice.actions
export default ingredientsSlice.reducer
