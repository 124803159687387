import React from 'react'

const year = new Date().getFullYear()

class Footer extends React.Component {
  static defaultProps = {
    containerSize: 'xxl',
  }

  render() {
    let { containerSize } = this.props

    return (
      <footer className="layout py-1" style={{ background: '#282828' }}>
        <div className={`container-${containerSize} text-end`} style={{ color: 'white' }}>
          Copyright &copy;{year} Nicelive - All rights reserved
        </div>
      </footer>
    )
  }
}
export default Footer
