import React from 'react'
import withJWTSession from 'src/enhancers/jwt_session'
import AppLayout from 'src/components/app_layout'
import Sidebar from 'src/components/admin/tenant_management/sidebar'
import Preview from 'src/components/admin/tenant_management/preview'
import Header from 'src/components/admin/tenant_management/header'

class PreviewPage extends React.Component {
  render() {
    let tenant_name = this.props.router.params.store_name

    return (
      <AppLayout containerSize={'fluid'} pageTitle="Preview" tenant={tenant_name}>
        <Header />
        <Sidebar />
        <Preview />
      </AppLayout>
    )
  }
}
export default withJWTSession({ invalid: '/admin/login' })(PreviewPage)
