import React from 'react'
import { Helmet } from 'react-helmet-async'
import { withRouter } from 'src/lib/router/with_router'

class ErrorMenuPage extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Menu digitale - errore locale non trovato</title>
        </Helmet>

        <div
          className="d-flex flex-column align-items-center justify-content-center flex-fill h-100"
          style={{
            background: 'url(/404.jpeg)',
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}>
          <div className="w-100 text-center py-3 text-white" style={{ background: '#00000080' }}>
            <div className="fw-bold" style={{ fontSize: '10rem' }}>
              404
            </div>
            <div style={{ fontSize: '3rem' }}>Locale/categoria non trovata!</div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(ErrorMenuPage)
