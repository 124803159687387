import React from 'react'

const year = new Date().getFullYear()

class FooterMenu extends React.Component {
  static defaultProps = {
    containerSize: 'xxl',
  }

  render() {
    let { containerSize } = this.props

    return (
      <>
        <style jsx="true">
          {`
            .footer_menu {
              background-image: url(/texture-footer-2.jpeg);
              background-position: 50%;
              background-repeat: no-repeat;
              background-size: cover;
            }
          `}
        </style>
        <footer className="layout py-1 footer_menu ">
          <div className={`container-${containerSize} text-center`} style={{ color: 'white' }}>
            <div className="fs-16">Copyright &copy;{year}</div>
            <div className="fs-14">
              <a
                href="https://www.nicelive.it"
                title="www.nicelive.it"
                className="text-white"
                target="_blank"
                rel="noreferrer">
                Nicelive.it
              </a>
            </div>
          </div>
        </footer>
      </>
    )
  }
}
export default FooterMenu
