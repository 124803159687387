import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { success, error } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  tenants: [],
  create_tenant_form: {
    is_pending: false,
    show_dialog: false,
    name: '',

    message: '',
    message_type: 'success',
  },
  current_tenant: {
    id: null,
    name: '',
    color_menu: '',
    color_background: '',
    color_secondary: '',
    medias: [],
    created_at: '',
  },
}

export const setCurrentTenant = createAsyncThunk('storeManager/setCurrentTenant', async (name, thunkApi) => {
  try {
    let response = await axios({ url: `/api/store_manager/tenant/${name}` })
    return response.data
  } catch (err) {
    error('Impossibile recuperare dati punto vendita')
    return thunkApi.rejectWithValue()
  }
})

export const retrieveTenants = createAsyncThunk('storeManager/retrieveTenants', async thunkApi => {
  try {
    let response = await axios({ url: `/api/store_manager/tenants` })
    return { tenants: response.data.tenants }
  } catch (err) {
    error('Impossibile recupeare lista punti vendita')
    return thunkApi.rejectWithValue()
  }
})

export const createTenant = createAsyncThunk('storeManager/createTenant', async ({ name }, thunkApi) => {
  try {
    await axios({ url: `/api/store_manager/tenant`, method: 'post', data: { name } })
    success('Punto vendita creato con successo')
    await thunkApi.dispatch(retrieveTenants())
    thunkApi.dispatch(toggleShowDialog())
  } catch (err) {
    let { message } = err?.response?.data ?? ''
    if (message === 'tenant_exists_error') {
      return thunkApi.rejectWithValue({
        message_type: 'danger',
        message: 'Tenant already exists',
      })
    } else {
      return thunkApi.rejectWithValue({
        message_type: 'danger',
        message: 'Unable to create tenant',
      })
    }
  }
})

export const updateCurrentTenantSettings = createAsyncThunk(
  'storeManager/updateCurrentTenantSettings',
  async ({ tenant_name, name, color_menu, color_background, color_secondary, logo }, thunkApi) => {
    try {
      await axios({
        url: `/api/store_manager/tenant/${tenant_name}`,
        method: 'PATCH',
        data: { name, color_menu, color_background, color_secondary, logo },
      })
      await thunkApi.dispatch(setCurrentTenant(name))
      success('Punto vendita aggiornato con successo')
      return { name, color_menu, color_background, color_secondary }
    } catch (err) {
      error('Impossibile aggiornare punto vendita')
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteTenant = createAsyncThunk(
  'storeManager/deleteTenant',
  async ({ name, onSuccess = () => {} }, thunkApi) => {
    try {
      await axios({ url: `/api/store_manager/tenant/${name}`, method: 'DELETE' })
      thunkApi.dispatch(retrieveTenants())
      onSuccess()
      success('Punto vendita eliminato con successo')
    } catch (err) {
      error('Impossibile eliminare punto vendita')
      return thunkApi.rejectWithValue()
    }
  }
)

export const storeManagerSlice = createSlice({
  name: 'storeManager',
  initialState,

  reducers: {
    resetTenants: state => {
      state.tenants = initialState.tenants
    },
    resetCurrentTenant: state => {
      state.current_tenant = initialState.current_tenant
    },
    // payload = <Object> {name, value}
    updateCreateFormData: (state, action) => {
      let { name, value } = action.payload
      state.create_tenant_form[name] = value
      state.create_tenant_form.message = ''
      state.create_tenant_form.message_type = 'success'
    },
    toggleShowDialog: state => {
      state.create_tenant_form.show_dialog = !state.create_tenant_form.show_dialog
      state.create_tenant_form.name = ''
      state.create_tenant_form.message = ''
      state.create_tenant_form.message_type = 'success'
    },
    // payload = <Object> {initialState.configs.settings}
    setTenantSettings: (state, action) => {
      state.configs.settings = action.payload
    },
    // payload = <Object> {initialState.configs.theme}
    setTenantTheme: (state, action) => {
      state.configs.theme = action.payload
    },
  },
  extraReducers: {
    [retrieveTenants.fulfilled]: (state, action) => {
      let { tenants } = action.payload
      state.tenants = tenants
    },
    [retrieveTenants.rejected]: () => {},

    [createTenant.pending]: state => {
      state.create_tenant_form.is_pending = true
    },
    [createTenant.fulfilled]: state => {
      state.create_tenant_form.is_pending = false
    },
    [createTenant.rejected]: (state, action) => {
      let { message_type, message } = action.payload
      state.create_tenant_form.message_type = message_type
      state.create_tenant_form.message = message
      state.create_tenant_form.is_pending = false
    },

    [setCurrentTenant.fulfilled]: (state, action) => {
      let { id, name, color_menu, color_background, color_secondary, medias, created_at } = action.payload
      state.current_tenant.id = id
      state.current_tenant.name = name
      state.current_tenant.color_menu = color_menu ?? ''
      state.current_tenant.color_background = color_background ?? ''
      state.current_tenant.color_secondary = color_secondary ?? ''
      state.current_tenant.medias = medias

      state.current_tenant.created_at = created_at
    },
    [setCurrentTenant.rejected]: state => {
      state.current_tenant = initialState.current_tenant
    },

    [updateCurrentTenantSettings.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { name, color_menu, color_background, color_secondary } = action.payload
      state.current_tenant.color_menu = color_menu
      state.current_tenant.color_background = color_background
      state.current_tenant.color_secondary = color_secondary
      state.current_tenant.name = name
    },
    [updateCurrentTenantSettings.rejected]: () => {},

    [deleteTenant.fulfilled]: state => {
      state.current_tenant = initialState.current_tenant
    },
    [deleteTenant.rejected]: () => {},
  },
})

export const {
  resetTenants,
  updateCreateFormData,
  toggleShowDialog,
  resetCurrentTenant,
  setTenantSettings,
  setTenantTheme,
} = storeManagerSlice.actions
export default storeManagerSlice.reducer
