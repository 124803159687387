import * as React from 'react'
import { Button, Form, Alert } from 'react-bootstrap'

import { updateFormData, tryLogin } from 'src/store/login'
import styles from './login_form.module.css'

class LoginVariant extends React.Component {
  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.handleSubmitLogin()
    }
  }

  handleChange = ({ target }) => {
    let { name, value } = target
    this.props.dispatch(updateFormData({ name, value }))
  }

  handleSubmitLogin = async () => {
    let { username, password } = this.props.loginData
    this.props.dispatch(tryLogin({ username, password }))
  }

  render() {
    let { maintenanceMode, loginData } = this.props
    let { message_type, message, username, password, loading } = loginData

    return (
      <div className={`card ${styles.content} pt-2`} style={{ background: 'rgb(255 255 255 / 84%)' }}>
        <div className="card-body">
          <div className="d-flex flex-row gap-2 align-items-center mb-2 text-center"></div>
          <div className="mb-5">
            {maintenanceMode === false && (
              <div className="mb-3 text-center">
                <h3>
                  <img src="/logo-nicelive.png" alt="logo nicelive" style={{ maxHeight: '5rem' }} />{' '}
                  <span className="mt-2">iceMenu</span>
                </h3>
                <h5 className="mt-2">Area di accesso</h5>
              </div>
            )}
            {maintenanceMode === true && (
              <Alert variant="danger" transition={false} className="mb-3">
                <Alert.Heading>Attenzione</Alert.Heading>
                Il sistema è in manutenzione. L'accesso è consentito solo all'amministratore
              </Alert>
            )}
          </div>
          <Alert variant={message_type} show={message !== ''} onClose={this.resetMessage} transition={false}>
            {message}
          </Alert>
          <Form className="mb-2" onKeyDown={this.handleKeyDown} noValidate>
            <Form.Group className="mb-3">
              <Form.Label>Username or email</Form.Label>
              <Form.Control name="username" placeholder="username" value={username} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="password"
                value={password}
                onChange={this.handleChange}
              />
            </Form.Group>
            <div className="mt-4">
              <div className="d-grid gap-2">
                <Button
                  className="btn-block"
                  color="primary"
                  size="md"
                  disabled={username === '' || password === '' || loading === true}
                  onClick={this.handleSubmitLogin}>
                  {loading === true && (
                    <>
                      <span className="spinner-border spinner-border-sm"></span> Login
                    </>
                  )}
                  {loading === false && 'Login'}
                </Button>
              </div>
              <button className="link-button mt-3" onClick={this.props.handleForgotPassword}>
                Password dimenticata?
              </button>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

export default LoginVariant
