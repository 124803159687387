import React from 'react'
import MenuLayout from 'src/components/menu_layout'
import HomeMenu from 'src/components/menu_digitale/home_menu'
import { withRouter } from 'src/lib/router/with_router'

class HomeMenuPage extends React.Component {
  render() {
    let { category_id, store_name: tenant_name } = this.props.router.params
    return (
      <MenuLayout tenant={tenant_name} pageTitle={'Menu digitale'}>
        <HomeMenu category_id={category_id} />
      </MenuLayout>
    )
  }
}

export default withRouter(HomeMenuPage)
