import React from 'react'
import { Routes, Route } from 'react-router-dom'
import NLApp from './nl_app'
import MenuApp from './menu_app'
import ErrorPage from './error-page'
import ErrorMenuPage from './error-menu-page'
import PreviewPage from 'src/pages/admin'
import UsersPage from 'src/pages/admin/users'
import LoginPage from 'src/pages/admin/login'
import CategoryPage from 'src/pages/admin/store_name/categories'
import IngredientPage from 'src/pages/admin/store_name/ingredients'
import TagPage from 'src/pages/admin/store_name/tags'
import ProductPage from 'src/pages/admin/store_name/products'
import RemoveTenantPage from 'src/pages/admin/store_name/remove_tenant'
import HomeMenuPage from '../pages/menu-digitale'
import ProductsMenuPage from '../pages/menu-digitale/products'

export default function NLRoute() {
  return (
    <>
      <Routes>
        <Route path="/" element={<NLApp />} />
        <Route path="/admin/*" element={<AdminRoute />} />
        <Route path="/menu-digitale/*" element={<HomeRoute />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  )
}

const AdminRoute = () => {
  return (
    <Routes>
      <Route element={<NLApp />}>
        <Route path="/" element={<PreviewPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/:store_name">
          <Route path="preview" element={<PreviewPage />} />
          <Route path="categories" element={<CategoryPage />} />
          <Route path="products" element={<ProductPage />} />
          <Route path="ingredients" element={<IngredientPage />} />
          <Route path="tags" element={<TagPage />} />
          <Route path="remove_tenant" element={<RemoveTenantPage />} />
        </Route>
        <Route path="login" element={<LoginPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  )
}

const HomeRoute = () => {
  return (
    <Routes>
      <Route element={<MenuApp />}>
        <Route path="/:store_name" element={<HomeMenuPage />}>
          <Route path="" element={<HomeMenuPage />} />
          <Route path="products/:category_id" element={<ProductsMenuPage />} />
        </Route>
        <Route path="*" element={<ErrorMenuPage />} />
      </Route>
    </Routes>
  )
}
