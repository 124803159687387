import * as React from 'react'

const PoweredBy = () => {
  return (
    <div style={{ float: 'right' }}>
      <div className="logo-area p-0">
        <div className="text-muted text-end" style={{ fontSize: '80%' }}>
          Powered by
        </div>
        {/* <img src="/logo_login.png" alt="" style={{ height: '3rem', marginTop: '0.25rem' }} /> */}

        <div className="d-flex">
          <style jsx="true">{`
            .compendium_text {
              font-size: 80%;
            }
            .compendium_text div:last-child {
              margin-top: -0.35rem;
            }
          `}</style>
          <div className="align-self-center">
            <div
              className="app_logo"
              style={{
                width: 80,
                height: 35,
                backgroundSize: 'cover',
                backgroundImage: `url('/logo.png')`,
              }}
            />
          </div>
          <div className="align-self-center ms-1 pt-2 compendium_text text-muted text-end"></div>
        </div>
      </div>
    </div>
  )
}

export default PoweredBy
