import axios from 'axios'
import { useEffect } from 'react'
import { resetStore } from 'src/store'
import { useNavigate, useLocation } from 'react-router-dom'

const instance = axios.create({
  baseURL: '/',
})

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleUnauthorized = () => {
    // error(t('session_expired_error', 'Session expired'))
    resetStore()
    if (location.pathname !== `/admin/login`) {
      navigate(`/admin/login`, { replace: true })
    }
  }
  useEffect(() => {
    const resInterceptor = response => {
      return response
    }

    const errInterceptor = error => {
      if (error.response?.data?.message === 'tenant_not_found') {
        handleUnauthorized()
      }
      if (error.response.status === 401) {
        if (error.response.config.url !== '/api/refresh_token') handleUnauthorized()
        return Promise.reject(error)
      }
    }
    const configInterceptor = config => {
      config.headers.refererpath = window.location.pathname
      return config
    }
    const errReqInterceptor = error => {
      return Promise.reject(error)
    }
    const interceptorResponse = instance.interceptors.response.use(resInterceptor, errInterceptor)
    const interceptorRequest = instance.interceptors.request.use(configInterceptor, errReqInterceptor)

    return () => {
      instance.interceptors.response.eject(interceptorResponse)
      instance.interceptors.request.eject(interceptorRequest)
    }
  })

  return children
}

export default instance
export { AxiosInterceptor }
