import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { withRouter } from 'src/lib/router/with_router'
import { Card } from 'react-bootstrap'
import { Transition } from 'react-transition-group'

import store from 'src/store'
import { setCurrentTenant, resetCurrentTenant } from 'src/store/store_manager'
import { getAllMenu } from 'src/store/digital_menu'

const duration = 300
const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}
const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const mstp = state => {
  let { name } = state.storeManager.current_tenant
  let { is_management_account } = state.userInfo
  return {
    name,
    is_management_account,
  }
}

class AppLayout extends React.PureComponent {
  static defaultProps = {
    containerSize: 'fluid',
    pageTitle: '',
    tenant: '',
    showSidebar: true,
  }

  state = { ready: false }

  nodeRef = React.createRef()

  async componentDidMount() {
    let { tenant, name } = this.props
    if (!name && tenant !== name) {
      await store.dispatch(getAllMenu({ tenant }))
    }
    if (name && !tenant) {
      await store.dispatch(resetCurrentTenant())
    }

    this.setState({ ready: true })
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.tenant !== this.props.tenant) {
      if (this.props.tenant) await store.dispatch(getAllMenu({ tenant: this.props.tenant }))
    }
  }

  render() {
    let { containerSize, pageTitle } = this.props
    let [Content] = React.Children.toArray(this.props.children)

    return (
      <>
        <style jsx="true">{`
          .global-sidebar {
            width: 22.5rem;
            min-width: 22.5rem;
          }
          body {
            background-image: url(/texture_2.jpg) !important;
            background-size: contain;
          }
        `}</style>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <div className={`container-${containerSize} flex-fill d-flex flex-column p-0`}>
          <Card
            className="flex-fill with-shadow bg-transparent"
            style={{ borderRadius: 'var(--bs-border-radius)', border: 'none' }}>
            <Card.Body className="d-flex flex-column p-0 bg-transparent">
              <Card.Text as="div" className="d-flex flex-column flex-fill">
                <div className="container-fluid mx-0 px-0 d-flex flex-fill">
                  <div className="d-flex flex-column flex-fill ">
                    {/* {showSidebar === true && <div className="p-0 d-flex mb-3 ms-lg-3">{Sidebar}</div>} */}
                    <Transition in={this.state.ready} timeout={duration} nodeRef={this.nodeRef}>
                      {state => (
                        <div
                          ref={this.nodeRef}
                          className="p-0 flex-fill d-flex flex-column"
                          style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                          }}>
                          {this.state.ready && Content}
                        </div>
                      )}
                    </Transition>
                  </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </>
    )
  }
}

export default connect(mstp)(withRouter(AppLayout))
