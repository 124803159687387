import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'src/lib/router/with_router'
import CategoryMenu from './category_menu'
import ProductMenu from './product_menu'
import { setCurrentCategory, resetCurrentCategory } from 'src/store/digital_menu'

const mstp = state => {
  let { name: tenant } = state.storeManager.current_tenant
  let { menu, current_category, ingredients, tags } = state.digitalMenu
  return { tenant, menu, current_category, ingredients, tags }
}
class HomeMenu extends React.Component {
  static defaultProps = {
    category_id: '',
  }
  state = {
    ready: false,
  }

  componentDidMount() {
    let { menu, category_id } = this.props
    if (category_id !== '') {
      let category = menu.categories.find(c => c.id === parseInt(category_id))
      if (category) {
        this.props.dispatch(setCurrentCategory(category))
      }
    }
  }

  handleChangeCategory = c => () => {
    this.props.dispatch(setCurrentCategory(c))
    let { navigate } = this.props.router
    navigate(`products/${c.id}`, { state: { category_id: c.id, store_name: this.props.tenant } })
  }

  handleResetCategory = () => {
    this.props.dispatch(resetCurrentCategory())
    let { navigate } = this.props.router
    navigate(``, { state: { category_id: '', store_name: this.props.tenant } })
  }

  render() {
    let { menu, current_category } = this.props

    return (
      <>
        <div className="container-fluid px-0">
          <div className="d-flex flex-column flex-fill">
            {!current_category && <CategoryMenu menu={menu} onChangeCategory={this.handleChangeCategory} />}
            {current_category && (
              <ProductMenu menu={menu} current_category={current_category} onBackCategory={this.handleResetCategory} />
            )}
          </div>
        </div>
      </>
    )
  }
}

export default connect(mstp)(withRouter(HomeMenu))
