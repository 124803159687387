import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { error } from 'src/components/system_wide/notification'
import axios from 'src/lib/axios'

const initialState = {
  menu: [],
  current_category: null,
  current_product: null,
  ingredients: [],
  tags: [],
}

export const getAllMenu = createAsyncThunk('digitalMenu/getAllMenu', async ({ tenant }, thunkApi) => {
  try {
    let response = await axios({
      url: `/api/menu/${tenant}`,
      method: 'get',
    })
    return response.data
  } catch (err) {
    error('Impossibile creare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const getIngredients = createAsyncThunk('digitalMenu/getIngredients', async ({ tenant }, thunkApi) => {
  try {
    let response = await axios({
      url: `/api/menu/${tenant}/ingredients`,
      method: 'get',
    })
    return response.data
  } catch (err) {
    error('Impossibile creare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const getTags = createAsyncThunk('digitalMenu/getTags', async ({ tenant }, thunkApi) => {
  try {
    let response = await axios({
      url: `/api/menu/${tenant}/tags`,
      method: 'get',
    })
    return response.data
  } catch (err) {
    error('Impossibile creare ruolo')
    return thunkApi.rejectWithValue()
  }
})

export const digitalMenuSlice = createSlice({
  name: 'digitalMenu',
  initialState,

  reducers: {
    setCurrentCategory: (state, action) => {
      state.current_category = action.payload
    },
    resetCurrentCategory: state => {
      state.current_category = null
    },
    resetProducts: state => {
      state.products = initialState.products
    },
    resetMenu: () => initialState,
  },

  extraReducers: {
    [getAllMenu.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { data } = action.payload
      state.menu = data
    },
    [getAllMenu.rejected]: state => {
      state.menu = initialState.menu
    },
    [getIngredients.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { data } = action.payload
      state.ingredients = data
    },
    [getIngredients.rejected]: state => {
      state.ingredients = initialState.ingredients
    },
    [getTags.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { data } = action.payload
      state.tags = data
    },
    [getTags.rejected]: state => {
      state.tags = initialState.tags
    },
  },
})

export const { setCurrentCategory, resetCurrentCategory, resetProducts, resetMenu } = digitalMenuSlice.actions
export default digitalMenuSlice.reducer
